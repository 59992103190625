import React, {useState, useEffect} from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import { Button, OverlayTrigger, Tooltip, Spinner  } from '@trimbleinc/modus-react-bootstrap';
import { useOktaAuth } from '@okta/okta-react';
import style from './page.module.css';
import Fab from '@mui/material/Fab';
import CreateIcon from '@mui/icons-material/Create';
import UpdateIcon from '@mui/icons-material/Update';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';




const DomainUpdate = () => {
    // console.log('data', domainContactsDetail)
    const [loading, setLoading] = useState('no');
    const [userInfo, setUserInfo] = useState({name:"",email:""});
    const { authState, oktaAuth } = useOktaAuth();
    useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
      //   setUserInfo(null);
        setUserInfo({name:null,email:null});
      } else {
        // setUserInfo(authState.idToken.claims);\
        oktaAuth.getUser().then((info) => {
          setUserInfo(info);
        });
      }
    }, [authState, oktaAuth]);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    useEffect(() => {
      const handleResize = () => {
        setScreenHeight(window.innerHeight);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const [domainContactsDetail, setDomainContactData] = useState([])
	var existingdomainNames = domainContactsDetail.map((row) => row.DomainName);																		
    const getApiData = async (api,setData) => {
        const response = await fetch(
          api
        ).then((response) => response.json());
      // console.log('hahaha',response)
      setData(response);
      };
    
	  const fetchData = () => { getApiData("https://software-procurement-catalog-dev.trimble.cloud/domainContactData",setDomainContactData);}
      useEffect(() => {
    
        fetchData();
      }, []);
      

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      const refreshApi = async event => {
        await sleep(2000)
        fetchData();
	  }

    const initialValues = {
        rows: [{ 
            id: Date.now(),
			action:'update',
            DomainName: '',
            DomainSponsorName: '',
            DomainSponsorEmail: '',
            DomainSponsorPhone: '',
            DomainLeadName: '',
            DomainLeadEmail: '',
            DomainLeadPhone: '',
            UserEmail: '',
            UpdatedTime: '',
        }],
      };

      const checkForDuplicatesEntries = (data) => {
        const seen = new Set();
        const duplicates = [];
        for (const item of data) {
          if (item.action === 'create'){
          const key = `${item.action}-${item.DomainName}`;
          if (seen.has(key)) {
            duplicates.push(item);
          } else {
            seen.add(key);
          }
        }
        }
        return duplicates
      };

      const handleSubmit = (values, { resetForm, setSubmitting }) => {

        if (values.rows.length === 0){
          handleWarningClick('Kindly Add Rows to update Domain details');
        }else {
          console.log(values.rows);
          var remaingValue = remainingArrays(values.rows,domainContactsDetail)
          var updatedDataToInsert = remainingArray(values.rows,domainContactsDetail)
          if (remaingValue.length === 0){
            handleWarningClick('There is no change in the Updated Domain Data');
          }else{
            var duplicates = checkForDuplicatesEntries(remaingValue)
            if (duplicates.length > 0) {
              handleErrorClick('Duplicate domain name entered, Each domain name must be unique for new entries')
            } else {
              setLoading('yes');
              handleInsertData(updatedDataToInsert,resetForm);
            }
            // handleClick()
            // resetForm();
          }
        }
        setSubmitting(false)
      };

      const handleInsertData = async(domainUpdatedContactData, resetForm) => {
        try {
            console.log('contact data', domainUpdatedContactData)
          const response = await axios.post('https://software-procurement-catalog-dev.trimble.cloud/domainUpdatedContactData',{ data: domainUpdatedContactData });
            
          console.log('response',response.data);
      if (response.data === 'Data inserted successfully' ) {
             refreshApi();
			 handleClick();
			 resetForm();
            setLoading('no');
           }
           else{
             console.log('error')
             handleErrorClick('Error on updating data in database')
             setLoading('no');
             console.log('something wrong')
           }
      }
        catch (error) {
            console.log("error is caught", error)
            setLoading('no');
            console.log('errror in ')
        }
      }

      // const phoneRegExp = /^(?:\(\+\d+\)|\+\d+|\(\d+\)|\d+)[\d\s-]{6,17}\d$/ ///^(?:\(\+\d+\)|\+\d+|\(\d+\))[\d\s-]{6,17}\d$/
      // const emailRegExp = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[cC][oO][mM]|null|Null|NULL)$/
      
	  const phoneRegExp =  /^[0-9+()\s-]{8,25}$/
      const emailRegExp =/^(?!.*\bnull\b)[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[cC][oO][mM]$/i
      const nameExp = /^(?![Nn][Uu][Ll][Ll]$)(?=.*[A-Za-z])['A-Za-z ]{3,50}$/ ;
	  
	  const validationSchema = Yup.object().shape({
        rows: Yup.array().of(
          Yup.object().shape({
            action: Yup.string().required('Action is required'),
            DomainName : Yup.string().test({
                name: 'conditional-validation',
                message: 'Domain Name already exists',
                test: function(value) {
                  const actionValue = this.parent.action;  // Access the value of 'action' field
                  const trimmedValue = value.trim().toLowerCase();
                  
                  if (actionValue === 'create') {
                    return !existingdomainNames.map(item => item.toLowerCase()).includes(trimmedValue);
                  }
                  return !!trimmedValue;
                }
              }).required('Domain Name is required'),
              DomainSponsorName : Yup.string().test('name-validation', function (value) {
							if (this.parent.action === 'update') {
							  const matchingItem = domainContactsDetail.find(
								originalItem => originalItem.DomainName.trim().toLowerCase() === this.parent.DomainName.trim().toLowerCase()
							  );
							  
							if (matchingItem.DomainSponsorName !== value) {
							  return nameExp.test(value) || this.createError({ message: 'Invalid Name' });
							}
							return true; // Return true if value is null or empty
						  } else { 
							return nameExp.test(value) || this.createError({ message: 'Invalid Name' });}
			  }).required('Name is required'),
              DomainSponsorEmail :Yup.string().test('conditional-validation', function (value) {
						if (this.parent.action === 'update') {
						  const matchingItem = domainContactsDetail.find(
							originalItem => originalItem.DomainName.trim().toLowerCase() === this.parent.DomainName.trim().toLowerCase()
						  );
						if (matchingItem.DomainSponsorEmail !== value) {
						  return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });
						}
						return true; // Return true if value is null or empty
					  } else { 
						return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });}
            }).required('Email is required'),
              DomainSponsorPhone : Yup.string().matches(phoneRegExp, 'Invalid Phone Number'),
              DomainLeadName : Yup.string().test('name-validation', function (value) { 
					if (this.parent.action === 'update') {
					  const matchingItem = domainContactsDetail.find(
						originalItem => originalItem.DomainName.trim().toLowerCase() === this.parent.DomainName.trim().toLowerCase()
					  );
					if (matchingItem.DomainLeadName !== value) {  
					  return nameExp.test(value) || this.createError({ message: 'Invalid Name' });
					}
					return true; // Return true if value is null or empty
				  } else { 
					return nameExp.test(value) || this.createError({ message: 'Invalid Name' });}
            }).required('Name is required'),
              DomainLeadEmail : Yup.string().test('conditional-validation', function (value) {
					if (this.parent.action === 'update') {
					  const matchingItem = domainContactsDetail.find(
						originalItem => originalItem.DomainName.trim().toLowerCase() === this.parent.DomainName.trim().toLowerCase()
					  );
					if (matchingItem.DomainLeadEmail !== value) {
					  return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });
					}
					return true; // Return true if value is null or empty
				  } else { 
					return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });}
            }).required('Email is required'),
              DomainLeadPhone : Yup.string().matches(phoneRegExp, 'Invalid Phone Number')
              
          })
        ),
      });

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    const [open, setOpen] = React.useState(false);
      const handleClick = () => {
        setOpen(true);
      };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    
      const [toastError, setToastError] = React.useState(false);
      const [errorMessage, setErrorMessage] = useState('');
      const handleErrorClick = (message) => {
        setErrorMessage(message)
        setToastError(true);
      };
    
      const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setToastError(false);
      };
    
      const [toastWarning, setToastWarning] = React.useState(false);
      const [warningMessage, setWarningMessage] = useState('');
      const handleWarningClick = (message) => {
        setWarningMessage(message);
        setToastWarning(true);
      };
    
      const handleWarningClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setToastWarning(false);
      };
      const remainingArrays = (randomArray,originalArray) => {
        return randomArray.filter(randomItem => {
          return !originalArray.some(originalItem => 
            originalItem.DomainName.trim().toLowerCase() === randomItem.DomainName.trim().toLowerCase() &&
            originalItem.DomainSponsorName.trim().toLowerCase() === randomItem.DomainSponsorName.trim().toLowerCase() &&
            originalItem.DomainSponsorEmail.trim().toLowerCase() === randomItem.DomainSponsorEmail.trim().toLowerCase() &&
            originalItem.DomainSponsorPhone.trim() === randomItem.DomainSponsorPhone &&
            originalItem.DomainLeadName.trim().toLowerCase() === randomItem.DomainLeadName.trim().toLowerCase() &&
            originalItem.DomainLeadEmail.trim().toLowerCase() === randomItem.DomainLeadEmail.trim().toLowerCase() &&
            originalItem.DomainLeadPhone.trim() === randomItem.DomainLeadPhone.trim()

          );
        });
      }

      const remainingArray = (randomArray, originalArray) => {
        return randomArray.map(randomItem => {
          const matchingItem = originalArray.find(
            originalItem => originalItem.DomainName === randomItem.DomainName
          );
      
          if (matchingItem) {
            const modifiedItem = { ...randomItem };
            if ((matchingItem.DomainSponsorName.trim().toLowerCase() === randomItem.DomainSponsorName.trim().toLowerCase()) || (randomItem.DomainSponsorName.trim().toLowerCase() === 'null')) {
              modifiedItem.DomainSponsorName = null;
            }
            if (matchingItem.DomainSponsorEmail.trim().toLowerCase() === randomItem.DomainSponsorEmail.trim().toLowerCase()) {
              modifiedItem.DomainSponsorEmail = null;
            }
            if (matchingItem.DomainSponsorPhone.trim() === randomItem.DomainSponsorPhone.trim()) {
              modifiedItem.DomainSponsorPhone = null;
            }
            if ((matchingItem.DomainLeadName.trim().toLowerCase() === randomItem.DomainLeadName.trim().toLowerCase()) || (randomItem.DomainLeadName.trim().toLowerCase() === 'null')) {
              modifiedItem.DomainLeadName = null;
            }
            if (matchingItem.DomainLeadEmail.trim().toLowerCase() === randomItem.DomainLeadEmail.trim().toLowerCase()) {
              modifiedItem.DomainLeadEmail = null;
            }
            if (matchingItem.DomainLeadPhone.trim() === randomItem.DomainLeadPhone.trim()) {
              modifiedItem.DomainLeadPhone = null;
            }
      
            return modifiedItem;
          } else {
            return randomItem;
          }
        });
      };
      
    

      const handleNameChange = (index, setFieldValue) => (event) => {
        const selectedName = event.target.value;
    
        // setRemovepn(selectedName)
        const selectedRow = domainContactsDetail.find((row) => row.DomainName === selectedName);
        if (selectedRow) {
          setFieldValue(`rows[${index}].DomainName`, selectedRow.DomainName);
          setFieldValue(`rows[${index}].DomainSponsorName`, selectedRow.DomainSponsorName);
          setFieldValue(`rows[${index}].DomainSponsorEmail`, selectedRow.DomainSponsorEmail);
          setFieldValue(`rows[${index}].DomainSponsorPhone`, selectedRow.DomainSponsorPhone);
          setFieldValue(`rows[${index}].DomainLeadName`, selectedRow.DomainLeadName);
          setFieldValue(`rows[${index}].DomainLeadEmail`, selectedRow.DomainLeadEmail);
          setFieldValue(`rows[${index}].DomainLeadPhone`, selectedRow.DomainLeadPhone);
          setFieldValue(`rows[${index}].UserEmail`, userInfo.email);
          setFieldValue(`rows[${index}].UpdatedTime`, new Date());
          
          
        }
        
      };
      const isValueInOtherRows = (array, value, index) => {
        return array.some((item, i) => i !== index && item.DomainName === value);
      };
      
      
      

  return (
    <>
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Updated Domain details successfully!
      </Alert>
    </Snackbar>
    <Snackbar open={toastError} autoHideDuration={4000} onClose={handleErrorClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
    <Snackbar open={toastWarning} autoHideDuration={4000} onClose={handleWarningClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleWarningClose} severity="warning" sx={{ width: '100%' }}>
        {warningMessage}
      </Alert>
    </Snackbar>
	{loading === 'yes' ? 
    // <div>hhh</div>
      <div className="text-center" style={{paddingTop: String(screenHeight/3).concat('px')}}>
        <Spinner animation="border" variant="primary" role="status" />
        <div className="h3 text-primary mt-2">Updating Domain Details...</div>
      </div>  
      : 
    <div style={{backgroundColor:'#f1f1f6'}}>
    <div className='display-3' style={{color:'#004f83', textAlign:'center',padding:'15px 0px 0px 0px'}}>Create/Update Domains</div> 
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
    {({ values, touched, errors, setFieldValue, handleChange, isSubmitting }) => (
    <Form style={{padding:'15px'}}>
            {isSubmitting && Object.keys(errors).length > 0 && (
              handleErrorClick('Kindly enter required details!')
            )}
        <Paper className={style.tableContainer} elevation={2} style={{height:String(screenHeight-170).concat('px')}}>
          {/* <div className='table-container'> */}
          <table className={style.table}>
            <thead style={{color: "#004F83", textAlign:'center'}}>
              <tr>
                <th className={`${style.commonFirstBeforeHeaderData} ${style.commonFirstHeader}`}>Domain Name <span style={{fontSize:'10px'}}> *</span></th>
                <th>Domain Sponsor Name<span style={{fontSize:'10px'}}> *</span></th>
                <th>Domain Sponsor Email<span style={{fontSize:'10px'}}> *</span></th>
                <th>Domain Sponsor Phone</th>
                <th>Domain Lead Name<span style={{fontSize:'10px'}}> *</span></th>
                <th>Domain Lead Email<span style={{fontSize:'10px'}}> *</span></th>
                <th>Domain Lead Phone</th>
                <th></th>
              </tr>
            
            </thead>
            <FieldArray name="rows">
            {({ insert, remove, push }) => (
                <>
                <tbody>
                {values.rows.map((row, index) => (
                    <tr>
                        <td className={`${style.commonFirstHeaderData} ${style.commonFirstData} ${style.commonFirstBeforeHeaderData}`}>
                        {row.action === 'update' ?
                        <OverlayTrigger
                          key="overlay1"
                          placement="top"
                          overlay={<Tooltip id="btntooltip">{row.DomainName ? row.DomainName : 'Select Domain'}</Tooltip>}
                          >
                          <Field
                            as="select"
                            name={`rows[${index}].DomainName`}
                            onChange={handleNameChange(index, setFieldValue)}
                            style={{border:
                              errors.rows?.[index]?.DomainName && touched.rows?.[index]?.DomainName
                                ? '1px solid red'
                                : '1px solid transparent', 
                              backgroundColor:'transparent', width:'250px'
                            }}
                          >
                            <option value="" style={{padding:"0.4rem 1rem"}}>Select Domain</option>
                            {domainContactsDetail.map((row) => (
                               <option 
                               key={row.DomainName} 
                               value={row.DomainName}
                               disabled={isValueInOtherRows(values.rows, row.DomainName, index)}
                               >
                                 {row.DomainName} 
                               </option>
                             ))}
                             
                             
                          </Field>
                          </OverlayTrigger>
                          :
                          <Field
                          type="text"
                          name={`rows[${index}].DomainName`}
                          placeholder='Enter Domain Name'
                          style={{
                            border: errors.rows?.[index]?.DomainName && touched.rows?.[index]?.DomainName ? '1px solid red' : '1px solid transparent',
                            backgroundColor: 'transparent',
                            width: '250px',
                            height:'30px'
                          }}
                        />
                          }
                          {errors.rows?.[index]?.DomainName && touched.rows?.[index]?.DomainName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].DomainName`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        
                        <Field
                          type="text"
                          name={`rows[${index}].DomainSponsorName`}
                          disabled={!values.rows[index].DomainName}
                          placeholder='Enter Sponsor Name'
                          value={row.DomainSponsorName}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.DomainSponsorName && touched.rows?.[index]?.DomainSponsorName
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field> 
                          {errors.rows?.[index]?.DomainSponsorName && touched.rows?.[index]?.DomainSponsorName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].DomainSponsorName`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
                        type="text"
                          name={`rows[${index}].DomainSponsorEmail`}
                          disabled={!values.rows[index].DomainName}
                          placeholder='Enter Sponsor Email'
                          value={row.DomainSponsorEmail}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.DomainSponsorEmail && touched.rows?.[index]?.DomainSponsorEmail
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.DomainSponsorEmail && touched.rows?.[index]?.DomainSponsorEmail && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].DomainSponsorEmail`} component="div" />
                            </span>
                          )}
                        </td>
                         
                        <td>
                        <Field
						  type="tel"
                          name={`rows[${index}].DomainSponsorPhone`}
                          disabled={!values.rows[index].DomainName}
                          placeholder='Enter Sponsor Phone'
                          value={row.DomainSponsorPhone}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.DomainSponsorPhone && touched.rows?.[index]?.DomainSponsorPhone
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.DomainSponsorPhone && touched.rows?.[index]?.DomainSponsorPhone && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].DomainSponsorPhone`}  />
                            </span>
                          )}
                        </td>
                        <td>
						
                        <Field
                          type="text"
                          name={`rows[${index}].DomainLeadName`}
                          disabled={!values.rows[index].DomainName}
                          placeholder='Enter Lead Name'
                          value={row.DomainLeadName}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.DomainLeadName && touched.rows?.[index]?.DomainLeadName
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>			   
                          {errors.rows?.[index]?.DomainLeadName && touched.rows?.[index]?.DomainLeadName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].DomainLeadName`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
						  type="text"
                          name={`rows[${index}].DomainLeadEmail`}
                          disabled={!values.rows[index].DomainName}
                          placeholder='Enter Lead Email'
                          value={row.DomainLeadEmail}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.DomainLeadEmail && touched.rows?.[index]?.DomainLeadEmail
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.DomainLeadEmail && touched.rows?.[index]?.DomainLeadEmail && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].DomainLeadEmail`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
                          type="tel"
                          name={`rows[${index}].DomainLeadPhone`}
                          disabled={!values.rows[index].DomainName}
                          placeholder='Enter Lead Phone'
                          value={row.DomainLeadPhone}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.DomainLeadPhone && touched.rows?.[index]?.DomainLeadPhone
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.DomainLeadPhone && touched.rows?.[index]?.DomainLeadPhone && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].DomainLeadPhone`}  />
                            </span>
                          )}
                        </td>
                        <td>
                          <Button
                          variant="text-danger"
                          size="sm" 
                          className="mr-2" 
                          type='button' 
                          onClick={() => {
                            remove(index);console.log(row.DomainName);
                            // setExistingNames(existingNames.concat(row.ProductName))
                          }} 
                          style={{padding:'7px 12px', fontSize:'15px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="mi-solid mi-delete" viewBox="0 0 24 24">
                              <path d="M18 4h-2.09c-.27 0-.52-.11-.71-.29h.01l-.41-.41a.99.99 0 0 0-.71-.29H9.92c-.27 0-.52.11-.71.29l-.41.41a.99.99 0 0 1-.71.29H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1ZM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12Zm8-9.49c0-.28.22-.5.5-.5s.5.22.5.5v9c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-9Zm-5 0c0-.28.22-.5.5-.5s.5.22.5.5v9c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-9Z"/>
                            </svg>
                          </Button>
                        </td>
                    </tr>


                ))}
                <br/>
                </tbody>
                </>

            )}
            </FieldArray>
           
        </table>
		<OverlayTrigger
            key="overlay2"
            placement="top"
            overlay={<Tooltip id="btntooltip">Create a New Domain</Tooltip>}
          >
          <Fab
            style={{backgroundColor:'#2A7DB2'}}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(18),
              right: (theme) => theme.spacing(2),
            }}
            onClick={() => setFieldValue('rows', [...values.rows, 
              { action:'create',
              DomainName: '',
              DomainSponsorName: '',
              DomainSponsorEmail: '',
              DomainSponsorPhone: '',
              DomainLeadName: '',
              DomainLeadEmail: '',
              DomainLeadPhone: '',
              UserEmail: userInfo.email,
              UpdatedTime: new Date(), }])}
          >
            <CreateIcon style={{color:'white'}} />
          </Fab>
          </OverlayTrigger>
          <OverlayTrigger
            key="overlay3"
            placement="top"
            overlay={<Tooltip id="btntooltip">Update Existing Domain</Tooltip>}
          >
        <Fab
            style={{backgroundColor:'#2A7DB2'}}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(10),
              right: (theme) => theme.spacing(1.7),
            }}
            onClick={() => setFieldValue('rows', [...values.rows, 
               { action:'update', DomainName: '',
                DomainSponsorName: '',
                DomainSponsorEmail: '',
                DomainSponsorPhone: '',
                DomainLeadName: '',
                DomainLeadEmail: '',
                DomainLeadPhone: '',
                UserEmail: '',
                UpdatedTime: '', }])}
          >
           <UpdateIcon style={{color:'white'}} />
          </Fab></OverlayTrigger>
        </Paper>
        <br/>
         <div className={style.buttonContainer}>
         <Button 
            variant="primary"
            size="sm" 
            className="mr-2 center-button" 
            type='submit' 
            style={{padding:'7px 12px', fontSize:'15px'}}>
            Submit
          </Button>
          
         </div>
        </Form>
    )}
    </Formik>
	</div>
}
    </>
  )
} ;
  
export default DomainUpdate;