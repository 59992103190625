import React from 'react'

function ProductDetailsPage(props) {
    const today = new Date();
    const selectedContractEndDate = new Date(props.result.ContractEndDate)
    const selectedContractStartDate = new Date(props.result.ContractStartDate)
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const startDate = selectedContractStartDate.toLocaleString('en-US', options)
    const endDate = selectedContractEndDate.toLocaleString('en-US', options)
  return (
    <div className='container-fluid'>
      <h1>{props.result.name}</h1>
        {props.domain.name === 'null' ? <h2>Domain : </h2> : <h2>Domain : {props.domain.name}</h2>}
          <br/>
          <div>
            {props.result.ProductDescription === 'null' ?
              <p></p>:
              <p>{props.result.ProductDescription}</p>
            }
          </div>
          <br/>
          <h3>Domain Contact Details</h3>
          <div className='row'>
            <div className='col-6'>
              <h5>Domain Sponsor</h5>
              <p>Name : {props.domain.DomainSponsorName}</p>
              <p>Email : {props.domain.DomainSponsorEmail}</p>
              <p>Phone : {props.domain.DomainSponsorPhone}</p>
            </div>
            <div className='col-6'>
              <h5>Domain Lead</h5>
              <p>Name : {props.domain.DomainLeadName}</p>
              <p>Email : {props.domain.DomainLeadEmail}</p>
              <p>Phone : {props.domain.DomainLeadPhone}</p>
            </div>
          </div>
          <br/>
          <h3>Vendor Contact Details</h3>
          <div className='row'>
            <div className='col-6'>
              <h5>Account Manager</h5>
              <p>Name : {props.result.VendorAccountManagerName}</p>
              <p>Email : {props.result.VendorAccountManagerEmail}</p>
              <p>Phone : {props.result.VendorAccountManagerPhone}</p>
            </div>
            <div className='col-6'>
              <h5>Technical Owner</h5>
              <p>Name : {props.result.VendorTechOwnerName}</p>
              <p>Email : {props.result.VendorTechOwnerEmail}</p>
              <p>Phone : {props.result.VendorTechOwnerPhone}</p>
            </div>
          </div>
          <br/>
          <h3>Trimble Contact Details</h3>
          <div className='row'>
            <div className='col-6'>
              <h5>Enablement Contact</h5>
              <p>Name : {props.result.TrimbleEnablementName}</p>
              <p>Email : {props.result.TrimbleEnablementEmail}</p>
              <p>Phone : {props.result.TrimbleEnablementPhone}</p>
            </div>
            <div className='col-6'>
              <h5>SME Contact</h5>
              <div>
                <p>Name : {(props.result.TrimbleSMEName).split(',')[0]}</p>
                {(props.result.TrimbleSMEName).split(',').map((item, index) => (
                  item.trim() !== '' && index !== 0  && <p key={index} style={{marginLeft:'50px'}}>{item.trim()}</p>
                ))}
              </div>
              <div>
                <p>Email : {(props.result.TrimbleSMEEmail).split(',')[0]}</p>
                {(props.result.TrimbleSMEEmail).split(',').map((item, index) => (
                  item.trim() !== '' && index !== 0  && <p key={index} style={{marginLeft:'50px'}}>{item.trim()}</p>
                ))}
              </div>
              {props.result.TrimbleSMEPhone === "null" ?
                <p>Phone : </p> :
                <div>
                  <p>Phone : {(props.result.TrimbleSMEPhone).split(',')[0]}</p>
                  {(props.result.TrimbleSMEPhone).split(',').map((item, index) => (
                    item.trim() !== '' && index !== 0  && <p key={index} style={{marginLeft:'50px'}}>{item.trim()}</p>
                  ))}
                </div>
              }
            </div>
          </div>
          <br/>
          <h3>Contract Info</h3>
          <div className='row'>
            <div className='col-6'>
              {/* {console.log('hhhhhhhhh',props.result.ContractEndDate)}
              {console.log('type',typeof(props.result.ContractEndDate))} */}
              {props.result.ContractEndDate === "null" ? 
                <p>Status : </p> :
                (selectedContractEndDate > today ?
                <p>Status : <span style={{backgroundColor:"#57D9A3",padding:"0px 4px", borderRadius:"4px"}}>Active</span></p> :
                <p>Status : <span style={{backgroundColor: "#FF5630",padding:"0px 4px", borderRadius:"4px"}}>Inactive</span></p>)
              }
              {props.result.ContractType === "null" ?
                <p>Contract Type : </p> :
                <p>Contract Type : {props.result.ContractType}</p>
              }
              {props.result.SupplierName === "null" ?
                '' :
                <p>Supplier : {props.result.SupplierName}</p>
              }
            </div>
            <div className='col-6'>
              {props.result.ContractEndDate === "null" ?
                <><p>Start Date : </p>
                <p>End Date : </p></> :
                <><p>Start Date : {startDate}</p>
                <p>End Date : {endDate}</p></>
              }
              
            </div>
          </div>         
    </div>
  )
}

export default ProductDetailsPage
