import React, { useState, useEffect } from 'react'
import { TreeView, TreeViewItem } from '@trimbleinc/modus-react-bootstrap'
import {Button} from '@trimbleinc/modus-react-bootstrap';
import Fab from '@mui/material/Fab';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
// import Tree1 from './Tree1';

export default function Tree(props) {
  const dd = [...Array(props.data.map((domain) => (domain.id)))][0];
  // console.log('/////',dd)

  const [expanded, setExpanded] = useState(dd);
  // useEffect(() => {
  //   // Fetch data or set your data here
  //   // const dd = [...Array(props.data.map((domain) => (domain.id)))][0];
    
  //   // Update the state with the fetched data
  //   setExpanded(dd);
  //   console.log('------',dd)
  // }, []); 

  
  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ?  [...Array(props.data.map((domain) => (domain.id)))][0] : [],
    );
  };

  const renderTree1 = (nodes) => (
    
    nodes.name === 'null' ? 
    <TreeViewItem style={{marginBottom:"5px", marginTop:"5px"}} key={nodes.name} nodeId={nodes.id} label={'Products without domain details'}>
        {Array.isArray(nodes.ProductName)
        ? nodes.ProductName.map((node) => renderTree2(node,nodes))
        : null}
    </TreeViewItem>
    :
    <TreeViewItem style={{marginBottom:"5px", marginTop:"5px"}} key={nodes.name} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.ProductName)
        ? nodes.ProductName.map((node) => renderTree2(node,nodes))
        : null}
    </TreeViewItem>
  );

  const renderTree2 = (productname,domain) => (
    <>
    {
      productname.name.toLowerCase().includes(props.search.toLowerCase()) && props.search !== '' ?
       <TreeViewItem style={{marginBottom:"5px", marginTop:"5px", backgroundColor: '#E0E1E9',fontWeight: 'bolder'}} onClick={() => props.res(productname,domain)} nodeId={productname.id} label={productname.name}></TreeViewItem>
    :
       <TreeViewItem style={{marginBottom:"5px", marginTop:"5px"}} onClick={() =>props.res(productname,domain)} nodeId={productname.id} label={productname.name} ></TreeViewItem>
  
    }
    </>

  );

  return (
    <div>
      {/* <Tree1/> */}
      <div className="item-container">
        <Fab
            style={{backgroundColor:'white', width: "20px", height:"20px"}}
            sx={{
              position: 'absolute',
              top: (theme) => theme.spacing(1),
              right: (theme) => theme.spacing(3.5),
            }}
            onClick={handleExpandClick}
          >
            {expanded.length === 0 ? <UnfoldMoreIcon/> : <UnfoldLessIcon/>}
          </Fab>
        <TreeView nodeId={0} id="borderless" className="list-group-borderless"
        expanded={expanded}
         size="sm">
        {props.data &&
            props.data.map((domain) => renderTree1(domain))}
        </TreeView>

      </div>
    </div>

  );
}
