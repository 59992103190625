import React, {useState, useEffect} from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import { Button, OverlayTrigger, Tooltip, Spinner } from '@trimbleinc/modus-react-bootstrap';
import { useOktaAuth } from '@okta/okta-react';
import style from './page.module.css';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import UpdateIcon from '@mui/icons-material/Update';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const ProductUpdate = () => {

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState({name:"",email:""});
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
    //   setUserInfo(null);
      setUserInfo({name:null,email:null});
    } else {
      // setUserInfo(authState.idToken.claims);\
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);
  // console.log(userInfo.email)
  // console.log(new Date())

  // Api Data
  const [existingData, setProductData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [lifecycleStatusData, setLifecycleStatusData] = useState([]);
  const [productStatusData, setProductStatusData] = useState([]);
  const [domainData, setDomainData] = useState([]);

  // Function to collect data
  const getApiData = async (api,setData) => {
    const response = await fetch(
      api
    ).then((response) => response.json())
    // .then(data => {setData(data)});
  // console.log('hahaha',response)
  setData(response);
  };
  
  const fetchData = () => {
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/productdata",setProductData);
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/vendordata",setVendorData);
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/lifecyclestatusdata",setLifecycleStatusData);
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/productstatusdata",setProductStatusData);
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/domaindata",setDomainData);
    }

  useEffect(() => {
    
    fetchData();

    // const intervalId = setInterval(() => {
    //   fetchData(); // Fetch data every 2 minutes
    // }, 1000);

    // return () => clearInterval(intervalId);

  }, []);

  const [loading, setLoading] = useState('no');
  const nameExp = /^(?![Nn][Uu][Ll][Ll]$)(?=.*[A-Za-z])['A-Za-z ]{3,50}$/ ;
  const phoneRegExp =  /^[0-9+()\s-]{8,25}$/ ;
  const emailRegExp =/^(?!.*\bnull\b)[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[cC][oO][mM]$/i ;

  const validateMultipleEmails = (value) => {
    const emails = value.split(',');
    const isValid = emails.every((email) => emailRegExp.test(email.trim()));
    return isValid || 'Invalid Email format';
  };
 
  const validationSchema = Yup.object().shape({
    rows: Yup.array().of(
      Yup.object().shape({
          action: Yup.string().required('Action is required'),
          ProductName: Yup.string().test({
            name: 'conditional-validation',
            message: 'Product Name already exists',
            test: function(value) {
              const actionValue = this.parent.action;  // Access the value of 'action' field
              const trimmedValue = value.trim().toLowerCase();
              
              if (actionValue === 'create') {
                return !existingNames.map(item => item.toLowerCase()).includes(trimmedValue);
              }
              return !!trimmedValue;
            }
          }).required('Product Name is required'),
          ProductDescription : Yup.string(),
          VendorName : Yup.string().required('Vendor Name is required'),
          LifecycleStatus : Yup.string().required('Lifecycle Status is required'),
          ProductStatus : Yup.string().required('Product Status is required'),
          DomainName : Yup.string().required('Domain Name is required'),
          TrimbleSMEName1 :  Yup.string().test(
            'name-validation',
            'Invalid Name',
            function (value) {
              if (this.parent.action === 'update') {
                const matchingItem = existingData.find(
                  originalItem => originalItem.ProductName.trim().toLowerCase() === this.parent.ProductName.trim().toLowerCase()
                );

                if (matchingItem) {
                  if (matchingItem.TrimbleSMEName1 !== value) {
                    return nameExp.test(value) || this.createError({ message: 'Invalid Name' });
                  }
                  return true; // Allow if value is unchanged
                }
              } else {
                return nameExp.test(value) || this.createError({ message: 'Invalid Name' });
              }
            }
          ).required('Primary SME Name is required'),
          TrimbleSMEEmail1 : Yup.string().test(
            'email-validation',
            'Invalid Email',
            function (value) {
              if (this.parent.action === 'update') {
                const matchingItem = existingData.find(
                  originalItem => originalItem.ProductName.trim().toLowerCase() === this.parent.ProductName.trim().toLowerCase()
                );

                if (matchingItem) {
                  if (matchingItem.TrimbleSMEEmail1 !== value) {
                    return emailRegExp.test(value) || this.createError({ message: 'Invalid Email' });
                  }
                  return true; // Allow if value is unchanged
                }
              } else {
                return emailRegExp.test(value) || this.createError({ message: 'Invalid Email' });
              }
            }
          ).required('Primary SME Email is required'),
          TrimbleSMEPhone1 : Yup.string().matches(phoneRegExp, 'Invalid Phone Number'),
          TrimbleSMEName2 : Yup.string().matches(nameExp, 'Invalid Name'),
          TrimbleSMEEmail2 : Yup.string().matches(emailRegExp, 'Invalid Email'),
          TrimbleSMEPhone2 : Yup.string().matches(phoneRegExp, 'Invalid Phone Number')
          // Tier : Yup.string(),
          
      })
    ),
  });

  const isValueInOtherRows = (array, value, index) => {
    return array.some((item, i) => i !== index && item.ProductName === value);
  };

  var existingNames = existingData.map((row) => row.ProductName);
  var existingVendorName = vendorData.map((row) => row.VendorName);
  var existingLifecycleStatus = lifecycleStatusData.map((row) => row.LifecycleStatusName);
  var existingProductStatus = productStatusData.map((row) => row.ProductStatusName);
  var existingDomainName = domainData.map((row) => row.DomainName);
  // var existingTier = [...new Set(existingData.map((row) => row.Tier))];
  // existingTier = existingTier.sort()
  // console.log(existingNames);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const initialValues = {
    rows: [{ 
        id: Date.now(),
        action:'update', //by default
        ProductName: '',
        ProductDescription: '',
        VendorName: '',
        LifecycleStatus: '',
        ProductStatus: '',
        DomainName: '',
        UserEmail: '',
        UpdatedTime: '',
        TrimbleSMEName1: '',
        TrimbleSMEEmail1: '',
        TrimbleSMEPhone1: '',
        TrimbleSMEName2: '',
        TrimbleSMEEmail2: '',
        TrimbleSMEPhone2: ''
        // Tier: ''
    }],
  };

  const handleNameChange = (index, setFieldValue) => (event) => {
    const selectedName = event.target.value;

    // setRemovepn(selectedName)
    const selectedRow = existingData.find((row) => row.ProductName === selectedName);
    if (selectedRow) {
      setFieldValue(`rows[${index}].ProductName`, selectedRow.ProductName);
      setFieldValue(`rows[${index}].ProductDescription`, selectedRow.ProductDescription);
      setFieldValue(`rows[${index}].VendorName`, selectedRow.VendorName);
      setFieldValue(`rows[${index}].LifecycleStatus`, selectedRow.LifecycleStatus);
      setFieldValue(`rows[${index}].ProductStatus`, selectedRow.ProductStatus);
      setFieldValue(`rows[${index}].DomainName`, selectedRow.DomainName);
      setFieldValue(`rows[${index}].TrimbleSMEName1`, selectedRow.TrimbleSMEName1);
      setFieldValue(`rows[${index}].TrimbleSMEEmail1`, selectedRow.TrimbleSMEEmail1);
      setFieldValue(`rows[${index}].TrimbleSMEPhone1`, selectedRow.TrimbleSMEPhone1);
      setFieldValue(`rows[${index}].TrimbleSMEName2`, selectedRow.TrimbleSMEName2);
      setFieldValue(`rows[${index}].TrimbleSMEEmail2`, selectedRow.TrimbleSMEEmail2);
      setFieldValue(`rows[${index}].TrimbleSMEPhone2`, selectedRow.TrimbleSMEPhone2);
      setFieldValue(`rows[${index}].UserEmail`, userInfo.email);
      setFieldValue(`rows[${index}].UpdatedTime`, new Date());
      // setHoveSrMessage(selectedRow.ProductName);
      // setFieldValue(`rows[${index}].Tier`, selectedRow.Tier);
    }
    
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [toastError, setToastError] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleErrorClick = (message) => {
    setErrorMessage(message)
    setToastError(true);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastError(false);
  };

  const [toastWarning, setToastWarning] = React.useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const handleWarningClick = (message) => {
    setWarningMessage(message);
    setToastWarning(true);
  };

  const handleWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastWarning(false);
  };
  
  const handleInsertData = async(SubmittedProductData,resetForm) => {
    try {
      // console.log(SubmittedProductData)
      const response = await axios.post('https://software-procurement-catalog-dev.trimble.cloud/productdata1',{ data: SubmittedProductData });
      console.log('response',response.data);
      if (response.data === 'Data inserted successfully'){
        refreshApi();
        handleClick();
        resetForm();
        setLoading('no');
      }else{
        console.log('error')
        setLoading('no');
        handleErrorClick('Error on updating data in database')
      }
  }
    catch (error) {
        console.log("error is caught", error)
        setLoading('no');
        handleErrorClick('Error on updating data in database')

        // return error
    }
  }

  // submit value check with existing value
  const remainingArray = (randomArray,originalArray) => {
    return randomArray.filter(randomItem => {
      return !originalArray.some(originalItem => 
        originalItem.DomainName === randomItem.DomainName && 
        originalItem.LifecycleStatus === randomItem.LifecycleStatus &&
        originalItem.ProductDescription === randomItem.ProductDescription &&
        originalItem.ProductName === randomItem.ProductName &&
        originalItem.ProductStatus === randomItem.ProductStatus &&
        originalItem.VendorName === randomItem.VendorName &&
        originalItem.TrimbleSMEName1 === randomItem.TrimbleSMEName1 &&
        originalItem.TrimbleSMEEmail1 === randomItem.TrimbleSMEEmail1 &&
        originalItem.TrimbleSMEPhone1 === randomItem.TrimbleSMEPhone1 &&
        originalItem.TrimbleSMEName2 === randomItem.TrimbleSMEName2 &&
        originalItem.TrimbleSMEEmail2 === randomItem.TrimbleSMEEmail2 &&
        originalItem.TrimbleSMEPhone2 === randomItem.TrimbleSMEPhone2
      );
    });
  }

  const checkForDuplicatesEntries = (data) => {
    const seen = new Set();
    const duplicates = [];
    for (const item of data) {
      if (item.action === 'create'){
      const key = `${item.action}-${item.ProductName}`;
      if (seen.has(key)) {
        duplicates.push(item);
      } else {
        seen.add(key);
      }
    }
  }
    return duplicates
  };

  const checkSMEChange = (UpdatedData) => {
    let output = [];
    let stopExecution = false;
    UpdatedData.forEach((item2) => {
      if(item2.TrimbleSMEEmail1 === item2.TrimbleSMEEmail2 && item2.TrimbleSMEEmail1 !== "" && item2.TrimbleSMEEmail2 !== ""){
        handleErrorClick('You have entered same SME Email in the primary and secondary contact');
        stopExecution = true;
      }
      else if (item2.action === 'update') {
        const correspondingItem = existingData.find((item1) => item1.ProductName === item2.ProductName);
  
        if (correspondingItem) {
          const updatedItem = { ...item2 };
  
          if (correspondingItem.TrimbleSMEEmail1 !== item2.TrimbleSMEEmail1) {
            updatedItem.prev_TrimbleSMEEmail1 = correspondingItem.TrimbleSMEEmail1;
            // if prev email is empty need to create a new contact so change in first sme goes to new 
            // because of creating new contact for the existing product else just update the contact data
            if (updatedItem.prev_TrimbleSMEEmail1 === '' || updatedItem.prev_TrimbleSMEEmail1.trim().toLowerCase() === 'null'){
              updatedItem.ChangesInFirstSME = 'new contact'
            }else if(updatedItem.TrimbleSMEEmail1 === ''){
              handleErrorClick('Kindly Enter SME Email');
              stopExecution = true;
              // throw new Error("Condition not met, stopping execution.");
            }else{
              updatedItem.ChangesInFirstSME = 'changes in contact'
            }
            
          }else if (correspondingItem.TrimbleSMEName1 !== item2.TrimbleSMEName1 || correspondingItem.TrimbleSMEPhone1 !== item2.TrimbleSMEPhone1){
            if(updatedItem.TrimbleSMEEmail1 === '' || updatedItem.TrimbleSMEEmail1.trim().toLowerCase() === 'null'){
              handleErrorClick('Kindly Enter SME Email to Update Contact Data');
              stopExecution = true;
              // throw new Error("Condition not met, stopping execution.");
            }else{
              updatedItem.ChangesInFirstSME = 'changes in contact'
            }
            
          }
          else{
            updatedItem.ChangesInFirstSME = 'no changes'
          }
  
          if (correspondingItem.TrimbleSMEEmail2 !== item2.TrimbleSMEEmail2) {
            updatedItem.prev_TrimbleSMEEmail2 = correspondingItem.TrimbleSMEEmail2;
            // if prev email is empty need to create a new contact so change in first sme goes to new 
            // because of creating new contact for the existing product else just update the contact data
            if (updatedItem.prev_TrimbleSMEEmail2 === ''){
              updatedItem.ChangesInSecondSME = 'new contact'
            }else if(updatedItem.TrimbleSMEEmail2 === ''){
              handleErrorClick('Kindly Enter SME Email');
              stopExecution = true;
              // throw new Error("Condition not met, stopping execution.");
            }else{
              updatedItem.ChangesInSecondSME = 'changes in contact'
            }
            
          }else if (correspondingItem.TrimbleSMEName2 !== item2.TrimbleSMEName2 || correspondingItem.TrimbleSMEPhone2 !== item2.TrimbleSMEPhone2){
            if(updatedItem.TrimbleSMEEmail2 === ''){
              handleErrorClick('Kindly Enter SME Email to Update Contact Data');
              stopExecution = true;
              // throw new Error("Condition not met, stopping execution.");
            }else{
              updatedItem.ChangesInSecondSME = 'changes in contact'
            }
            
          }else{
            updatedItem.ChangesInSecondSME = 'no changes'
          }
  
          output.push(updatedItem);
        } else {
          output.push(item2);
        }
      } 
      // else condition
      else{
        const createItem = {...item2}
        // create contact one
        if(item2.TrimbleSMEEmail1){
          createItem.createSME1 = 'new contact'
        }else if(item2.TrimbleSMEName1 || item2.TrimbleSMEPhone1){
          handleErrorClick('Kindly Enter SME Email');
          stopExecution = true;
          // throw new Error("Condition not met, stopping execution.");
        }
        // create contact two
        if(item2.TrimbleSMEEmail2){
          createItem.createSME2 = 'new contact'
        }else if(item2.TrimbleSMEName2 || item2.TrimbleSMEPhone2){
          handleErrorClick('Kindly Enter SME Email');
          stopExecution = true;
          // throw new Error("Condition not met, stopping execution.");
        }

        output.push(createItem);

      }
      // else {
      //   output.push(item2);
      // }
    });
    return [output,stopExecution];
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  // update or create action
  const handleActionChange = (index, setFieldValue) => (event) => {
    const action = event.target.value;
    setFieldValue(`rows[${index}].action`, action);
    // Clear product name and vendor name when switching between 'update' and 'create'
    if (action === 'create') {
      setFieldValue(`rows[${index}].ProductName`, '');
      setFieldValue(`rows[${index}].ProductDescription`, '');
      setFieldValue(`rows[${index}].VendorName`, '');
      setFieldValue(`rows[${index}].LifecycleStatus`, '');
      setFieldValue(`rows[${index}].ProductStatus`, '');
      setFieldValue(`rows[${index}].DomainName`, '');
      setFieldValue(`rows[${index}].UserEmail`, userInfo.email);
      setFieldValue(`rows[${index}].UpdatedTime`, new Date());


    }
  };
  // update or create action
  
  const handleSubmit = (values, { resetForm, setSubmitting }) => {

    if (values.rows.length === 0){
      handleWarningClick('Kindly Add Rows to update product details');
    }else {
      // console.log(values.rows);
      var remaingValue = remainingArray(values.rows,existingData)
      if (remaingValue.length === 0){
        handleWarningClick('There is no change in the Updated Products Data');
      }else{
        var duplicates = checkForDuplicatesEntries(remaingValue)
        if (duplicates.length > 0) {
          handleErrorClick('Duplicate product name entered, Each product name must be unique for new entries.')
        } else {
          // console.log(remaingValue);
          var [outputData,stopExecution] = checkSMEChange(remaingValue)
          console.log('Output Data')
          console.log(outputData)
          console.log('stop Execution')
          console.log(stopExecution)
          if(!stopExecution){
            setLoading('yes');
            handleInsertData(outputData,resetForm);
          }
          
        }
        
      }
    }
    setSubmitting(false)

    // console.log('value')
  };

  const refreshApi = async event => {
    // await delay(10000)
    fetchData();
  }

  return (
    <>
    {/* {fetchData()} */}
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Updated product details successfully!
      </Alert>
    </Snackbar>
    <Snackbar open={toastError} autoHideDuration={2000} onClose={handleErrorClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
    <Snackbar open={toastWarning} autoHideDuration={4000} onClose={handleWarningClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleWarningClose} severity="warning" sx={{ width: '100%' }}>
        {warningMessage}
      </Alert>
    </Snackbar>
    {loading === 'yes'? 
    // <div>hhh</div>
      <div className="text-center" style={{paddingTop: String(screenHeight/3).concat('px')}}>
        <Spinner animation="border" variant="primary" role="status" />
        <div className="h3 text-primary mt-2">Updating Product Details...</div>
      </div>  
      : 
      <div style={{backgroundColor:'#f1f1f6'}}>
      <div className='display-3' style={{color:'#004f83', textAlign:'center',padding:'15px 0px 0px 0px'}}>Create/Update Products</div>  
     <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ values, touched, errors, setFieldValue, handleChange, isSubmitting }) => (
        // <Paper elevation={2} style={{height:String(screenHeight-200).concat('px'), padding:"30px", margin:"30px", overflow:'auto'}}>
          <Form style={{padding:'18px'}}>
            {isSubmitting && Object.keys(errors).length > 0 && (
              handleErrorClick('Kindly enter required details!')
            )}
          <Paper className={style.tableContainer} elevation={2} style={{height:String(screenHeight-220).concat('px')}}>
          {/* <div className='table-container'> */}
          <table className={style.table}>
            <thead style={{color: "#004F83", textAlign:'center'}}>
              <tr>
                {/* <th>{data[0].ProductName}</th> */}
                {/* <th>Action</th> */}
                <th className={`${style.commonFirstBeforeHeaderData} ${style.commonFirstHeader}`}>Product Name <span style={{fontSize:'10px'}}> *</span></th>
                <th>Product Description</th>
                <th>Vendor Name <span style={{fontSize:'10px'}}> *</span></th>
                <th>Lifecycle Status <span style={{fontSize:'10px'}}> *</span></th>
                <th>Product Status <span style={{fontSize:'10px'}}> *</span></th>
                <th>Domain Name <span style={{fontSize:'10px'}}> *</span></th>
                <th>Primary SME Name <span style={{fontSize:'10px'}}> *</span></th>
                <th>Primary SME Email <span style={{fontSize:'10px'}}> *</span></th>
                <th>Primary SME Phone</th>
                <th>Secondary SME Name</th>
                <th>Secondary SME Email</th>
                <th>Secondary SME Phone</th>
                {/* <th>Tier</th> */}
                <th></th>
              </tr>
            </thead><FieldArray name="rows">
            
              
                {({ insert, remove, push }) => (
                  <>
                   <tbody>
                    {values.rows.map((row, index) => (
                      <tr key={row.id}>
                        {/* update or create dropdown */}
                        {/* <td>
                            <Field
                              as="select"
                              name={`rows[${index}].action`}
                              onChange={handleActionChange(index, setFieldValue)}
                              style={{
                                border: errors.rows?.[index]?.action && touched.rows?.[index]?.action ? '1px solid red' : '1px solid transparent',
                                backgroundColor: 'transparent',
                                width: '250px',
                              }}
                            >
                              <option value="update">Update Existing Product</option>
                              <option value="create">Create a New Product</option>
                            </Field>
                            {errors.rows?.[index]?.action && touched.rows?.[index]?.action && (
                            <span className='error'>
                              <ErrorMessage name={`rows[${index}].action`}  />
                            </span>
                          )}
                          </td> */}
                        {/* update or create dropdown */}
                        <td className={`${style.commonFirstHeaderData} ${style.commonFirstData} ${style.commonFirstBeforeHeaderData}`}>
                          {/* Replace text input with a dropdown */}
                          {row.action === 'update' ? 
                          <OverlayTrigger
                          key="overlay1"
                          placement="top"
                          overlay={<Tooltip id="btntooltip">{row.ProductName === '' ? 'Select ProductName' : row.ProductName}</Tooltip>}
                          >
                          <Field
                            as="select"
                            name={`rows[${index}].ProductName`}
                            onChange={handleNameChange(index, setFieldValue)}
                            style={{border:
                              errors.rows?.[index]?.ProductName && touched.rows?.[index]?.ProductName
                                ? '1px solid red'
                                : '1px solid transparent', 
                              backgroundColor:'transparent', width:'250px',
                            height:'30px'
                               
                              // borderRadius:'0.25rem', 
                              // padding: "0.4rem 1rem",
                              // fontSize:"1rem",
                              // textAlign:"center",
                              // color:"#004F83",
                              // fontWeight: "545"
                            }}
                          >
                            <option value="" style={{padding:"0.4rem 1rem"}}>Select ProductName</option>
                            {existingNames.map((ProductName) => (
                               <option 
                               key={ProductName} 
                               value={ProductName}
                               disabled={isValueInOtherRows(values.rows, ProductName, index)}
                               >
                                 {ProductName} 
                               </option>
                             ))}
                             
                          </Field>
                          </OverlayTrigger> :
                          <Field
                          type="text"
                          name={`rows[${index}].ProductName`}
                          placeholder='Enter Product Name'
                          style={{
                            border: errors.rows?.[index]?.ProductName && touched.rows?.[index]?.ProductName ? '1px solid red' : '1px solid transparent',
                            backgroundColor: 'transparent',
                            width: '250px',
                            height:'30px'
                          }}
                        />
                          }
                          
                          {errors.rows?.[index]?.ProductName && touched.rows?.[index]?.ProductName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].ProductName`} component="div" />
                            </span>
                          )}
                        </td>
                        
                        {/* <td style={{padding:'0px 0px 0px 20px'}}> */}
                        <td>
                          {/* <Field
                            // type="textarea"
                            name={`rows[${index}].ProductDescription`}
                            // disabled={!values.rows[index].ProductName} // Allow editing only if a name is selected
                          /> */}
                          <textarea
                          name={`rows[${index}].ProductDescription`}
                          disabled={!values.rows[index].ProductName}
                          placeholder='Enter Product description'
                          value={row.ProductDescription}
                          onChange={handleChange}
                          style={{
                            border:'2px solid transparent',
                            backgroundColor:'transparent',
                            width:'300px',
                            height:'90px',
                          }}
                          >
                          </textarea>
                        </td>
                        <td>
                          <Field
                            as="select"
                            name={`rows[${index}].VendorName`}
                            style={{
                              border:
                                errors.rows?.[index]?.VendorName && touched.rows?.[index]?.VendorName
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                                  
                            }}
                            disabled={!values.rows[index].ProductName}
                          >
                            <option value="">Select Vendor Name</option>
                            {existingVendorName.map((VendorName) => (
                              <option 
                              key={VendorName} 
                              value={VendorName}
                              disabled={VendorName === 'null'}
                              >
                                {VendorName}
                              </option>
                            ))}
                          </Field>
                          {errors.rows?.[index]?.VendorName && touched.rows?.[index]?.VendorName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].VendorName`} component="div" />
                            </span>
                          )}
                        </td>
                        {/* <td style={{padding:'0px 0px 0px 20px'}}> */}
                        <td>
                          {/* Replace text input with a dropdown */}
                          <Field
                            as="select"
                            name={`rows[${index}].LifecycleStatus`}
                            disabled={!values.rows[index].ProductName}
                            style={{
                              border:
                                errors.rows?.[index]?.LifecycleStatus && touched.rows?.[index]?.LifecycleStatus
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                          >
                            <option value="">Select Lifecycle Status</option>
                            {existingLifecycleStatus.map((LifecycleStatus) => (
                              <option 
                              key={LifecycleStatus} 
                              value={LifecycleStatus}
                              // disabled={LifecycleStatus === 'null'}
                              >
                                {LifecycleStatus}
                              </option>
                            ))}
                            <option disabled>null</option>
                          </Field>
                          {errors.rows?.[index]?.LifecycleStatus && touched.rows?.[index]?.LifecycleStatus && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].LifecycleStatus`} component="div" />
                            </span>
                          )}
                        </td>
                        {/* <td style={{padding:'0px 0px 0px 20px'}}> */}
                        <td>
                          {/* Replace text input with a dropdown */}
                          <Field
                            as="select"
                            name={`rows[${index}].ProductStatus`}
                            style={{
                              border:
                                errors.rows?.[index]?.ProductStatus && touched.rows?.[index]?.ProductStatus
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            disabled={!values.rows[index].ProductName}
                          >
                            <option value="">Select Product Status</option>
                            {existingProductStatus.map((ProductStatus) => (
                              <option 
                              key={ProductStatus} 
                              value={ProductStatus}
                              // disabled={ProductStatus === 'null'}
                              >
                                {ProductStatus}
                              </option>
                            ))}
                            <option disabled>null</option>
                          </Field>
                          {errors.rows?.[index]?.ProductStatus && touched.rows?.[index]?.ProductStatus && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].ProductStatus`} component="div" />
                            </span>
                          )}
                        </td>
                        {/* <td style={{padding:'0px 0px 0px 20px'}}> */}
                        <td>
                          {/* Replace text input with a dropdown */}
                          <Field
                            as="select"
                            name={`rows[${index}].DomainName`}
                            style={{
                              border:
                                errors.rows?.[index]?.DomainName && touched.rows?.[index]?.DomainName
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            disabled={!values.rows[index].ProductName}
                          >
                            <option value="">Select Domain Name</option>
                            {existingDomainName.map((DomainName) => (
                              <option 
                              key={DomainName} 
                              value={DomainName}
                              disabled={DomainName === 'null'}
                              >
                                {DomainName}
                              </option>
                            ))}
                            <option disabled>null</option>
                          </Field>
                          {errors.rows?.[index]?.DomainName && touched.rows?.[index]?.DomainName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].DomainName`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type='text'
                            name={`rows[${index}].TrimbleSMEName1`}
                            placeholder='Enter Primary SME Name'
                            style={{
                              border:
                                errors.rows?.[index]?.TrimbleSMEName1 && touched.rows?.[index]?.TrimbleSMEName1
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'250px',height:'30px'
                            }}
                            disabled={!values.rows[index].ProductName}
                          />
                          {errors.rows?.[index]?.TrimbleSMEName1 && touched.rows?.[index]?.TrimbleSMEName1 && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleSMEName1`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type='text'
                            name={`rows[${index}].TrimbleSMEEmail1`}
                            placeholder='Enter Primary SME Email'
                            style={{
                              border:
                                errors.rows?.[index]?.TrimbleSMEEmail1 && touched.rows?.[index]?.TrimbleSMEEmail1
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'250px',height:'30px'
                            }}
                            disabled={!values.rows[index].ProductName}
                          />
                          {errors.rows?.[index]?.TrimbleSMEEmail1 && touched.rows?.[index]?.TrimbleSMEEmail1 && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleSMEEmail1`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type='text'
                            name={`rows[${index}].TrimbleSMEPhone1`}
                            placeholder='Enter Primary SME Phone Number'
                            style={{
                              border:
                                errors.rows?.[index]?.TrimbleSMEPhone1 && touched.rows?.[index]?.TrimbleSMEPhone1
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'250px',height:'30px'
                            }}
                            disabled={!values.rows[index].ProductName}
                          />
                          {errors.rows?.[index]?.TrimbleSMEPhone1 && touched.rows?.[index]?.TrimbleSMEPhone1 && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleSMEPhone1`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type='text'
                            name={`rows[${index}].TrimbleSMEName2`}
                            placeholder='Enter Secondary SME Name'
                            style={{
                              border:
                                errors.rows?.[index]?.TrimbleSMEName2 && touched.rows?.[index]?.TrimbleSMEName2
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'250px',height:'30px'
                            }}
                            disabled={!values.rows[index].ProductName}
                          />
                          {errors.rows?.[index]?.TrimbleSMEName2 && touched.rows?.[index]?.TrimbleSMEName2 && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleSMEName2`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type='text'
                            name={`rows[${index}].TrimbleSMEEmail2`}
                            placeholder='Enter Secondary SME Email'
                            style={{
                              border:
                                errors.rows?.[index]?.TrimbleSMEEmail2 && touched.rows?.[index]?.TrimbleSMEEmail2
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'250px',height:'30px'
                            }}
                            disabled={!values.rows[index].ProductName}
                          />
                          {errors.rows?.[index]?.TrimbleSMEEmail2 && touched.rows?.[index]?.TrimbleSMEEmail2 && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleSMEEmail2`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type='text'
                            name={`rows[${index}].TrimbleSMEPhone2`}
                            placeholder='Enter Secondary SME Phone Number'
                            style={{
                              border:
                                errors.rows?.[index]?.TrimbleSMEPhone2 && touched.rows?.[index]?.TrimbleSMEPhone2
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'250px',height:'30px'
                            }}
                            disabled={!values.rows[index].ProductName}
                          />
                          {errors.rows?.[index]?.TrimbleSMEPhone2 && touched.rows?.[index]?.TrimbleSMEPhone2 && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleSMEPhone2`} component="div" />
                            </span>
                          )}
                        </td>
                        {/* <td style={{padding:'0px 0px 0px 20px'}}> */}
                        {/* <td>
                          <Field
                            as="select"
                            name={`rows[${index}].Tier`}
                            disabled={!values.rows[index].ProductName}
                            style={{
                              border:'1px solid transparent', 
                              backgroundColor:'transparent', 
                            }}
                          >
                            <option value="">Select Tier</option>
                            {existingTier.map((Tier) => (
                              <option 
                              key={Tier} 
                              value={Tier}
                              disabled={Tier === 'null'}
                              >
                                {Tier}
                              </option>
                            ))}
                          </Field>
                        </td> */}
                        <td>
                          {/* <button type="button" onClick={() => remove(index)}>
                            Remove
                          </button> */}
                          <Button
                          variant="text-danger"
                          size="sm" 
                          className="mr-2" 
                          type='button' 
                          onClick={() => {
                            remove(index);console.log(row.ProductName);
                            // setExistingNames(existingNames.concat(row.ProductName))
                          }} 
                          style={{padding:'7px 12px', fontSize:'15px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="mi-solid mi-delete" viewBox="0 0 24 24">
                              <path d="M18 4h-2.09c-.27 0-.52-.11-.71-.29h.01l-.41-.41a.99.99 0 0 0-.71-.29H9.92c-.27 0-.52.11-.71.29l-.41.41a.99.99 0 0 1-.71.29H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1ZM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12Zm8-9.49c0-.28.22-.5.5-.5s.5.22.5.5v9c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-9Zm-5 0c0-.28.22-.5.5-.5s.5.22.5.5v9c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-9Z"/>
                            </svg>
                          </Button>
                        </td>
                      </tr>
                    ))}
                    <br/>
                    </tbody>
                  {/* <tfoot>
                        <Button 
                          variant="outline-primary"
                          size="sm" 
                          className="mr-2" 
                          type='button' 
                          onClick={() => {
                            push({ id: Date.now(), ProductName: '', ProductDescription: '', VendorName: '', LifecycleStatus: '', ProductStatus: '', DomainName: ''});
                          }}
                          style={{padding:'7px 14px', fontSize:'15px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="mi-solid mi-add-circle" viewBox="0 0 24 24">
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm4 11h-3v3c0 .55-.45 1-1 1s-1-.45-1-1v-3H8c-.55 0-1-.45-1-1s.45-1 1-1h3V8c0-.55.45-1 1-1s1 .45 1 1v3h3c.55 0 1 .45 1 1s-.45 1-1 1Z"/>
                            </svg>
                            {' Add Row'}
                          </Button>
                    </tfoot> */}
                    </>
                )}
              </FieldArray>
          </table>
          <OverlayTrigger
            key="overlay2"
            placement="top"
            overlay={<Tooltip id="btntooltip">Create a New Product</Tooltip>}
          >
          <Fab
            style={{backgroundColor:'#2A7DB2'}}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(18),
              right: (theme) => theme.spacing(2),
            }}
            onClick={() => setFieldValue('rows', [...values.rows, 
              { action:'create',
                ProductName: '',
                ProductDescription: '',
                VendorName: '',
                LifecycleStatus: '',
                ProductStatus: '',
                DomainName: '',
                TrimbleSMEName1:'',
                TrimbleSMEEmail1:'',
                TrimbleSMEPhone1:'',
                TrimbleSMEName2:'',
                TrimbleSMEEmail2:'',
                TrimbleSMEPhone2:'',
                UserEmail: userInfo.email,
                UpdatedTime: new Date(), }])}
          >
            <CreateIcon style={{color:'white'}} />
          </Fab>
          </OverlayTrigger>
          <OverlayTrigger
            key="overlay3"
            placement="top"
            overlay={<Tooltip id="btntooltip">Update Existing Product</Tooltip>}
          >
          <Fab
            style={{backgroundColor:'#2A7DB2'}}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(10),
              right: (theme) => theme.spacing(2),
            }}
            onClick={() => setFieldValue('rows', [...values.rows, 
              { action:'update',
                ProductName: '',
                ProductDescription: '',
                VendorName: '',
                LifecycleStatus: '',
                ProductStatus: '',
                DomainName: '',
                TrimbleSMEName1:'',
                TrimbleSMEEmail1:'',
                TrimbleSMEPhone1:'',
                TrimbleSMEName2:'',
                TrimbleSMEEmail2:'',
                TrimbleSMEPhone2:'',
                UserEmail: '',
                UpdatedTime: '', }])}
          >
            <UpdateIcon style={{color:'white'}} />
          </Fab>
          </OverlayTrigger>
         </Paper>
         <br/>
         <div className={style.buttonContainer}>
          <Button 
            variant="primary"
            size="sm" 
            className="mr-2 center-button" 
            type='submit' 
            style={{padding:'7px 12px', fontSize:'15px'}}
            //  onClick={ss}
             >
            Submit
          </Button>
          </div>
        </Form>
        //{/* </Paper> */}
      )}
    </Formik>
    </div>
    }
    </>
  );
};

export default ProductUpdate;