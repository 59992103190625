import React, {useState, useEffect} from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import { Button, OverlayTrigger, Tooltip, Spinner } from '@trimbleinc/modus-react-bootstrap';
import { useOktaAuth } from '@okta/okta-react';
import style from './page.module.css';
import Fab from '@mui/material/Fab';
import CreateIcon from '@mui/icons-material/Create';
import UpdateIcon from '@mui/icons-material/Update';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Contract = () => {

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState({name:"",email:""});
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo({name:null,email:null});
    } else {
      // setUserInfo(authState.idToken.claims);\
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);


  // Api Data
  const [productData, setProductData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [vendorProductData, setVendorProductData] = useState([]);

  // Function to collect data
  const getApiData = async (api,setData) => {
    const response = await fetch(
      api
    ).then((response) => response.json())
  setData(response);
  };
  
  const fetchData = () => {
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/productdata",setProductData);
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/vendordata",setVendorData);
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/contractdata",setContractData);
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/vendorproductdata",setVendorProductData);
    }

  useEffect(() => {
    
    fetchData();

  }, []);

  const [loading, setLoading] = useState('no');
  const nameExp = /^(?![Nn][Uu][Ll][Ll]$)(?=.*[A-Za-z])['A-Za-z ]{3,50}$/ ;
  const validationSchema = Yup.object().shape({
    rows: Yup.array().of(
      Yup.object().shape({
          action: Yup.string().required('Action is required'),
          VendorName : Yup.string().required('Vendor Name is required'),
          ProductName : Yup.string().required('Product Name is required'),
          StartDate : Yup.string().required('Start Date is required'),
          EndDate : Yup.string().test({
            name: 'conditional-validation',
            message: 'EndDate already exists for the specified product, vendor and start date',
            test: function(value) {
              const actionValue = this.parent.action;  // Access the value of 'action' field
              const vendorValue = this.parent.VendorName;
              const productValue = this.parent.ProductName;
              const startDateValue = this.parent.StartDate;
              const trimmedValue = value.trim();
              
              if (actionValue === 'create'){
              const exists = contractData.some(item => {
                return (
                  item.VendorName === vendorValue &&
                  item.ProductName === productValue &&
                  formatDate(item.StartDate) === formatDate(startDateValue)
                  &&
                  formatDate(item.EndDate) === formatDate(trimmedValue)
                );
              });
              return !exists;
            }
              return !!trimmedValue;
            }
          }).required('End Date is required'),
          Scope : Yup.string().required('Scope is required'),
          Division: Yup.string().test({
            name: 'conditional-validation',
            message: 'Invalid Division',
            test: function (value) {
              const scopeValue = this.parent.Scope;
              const trimmedValue = String(value).trim().toLowerCase();
              if (scopeValue === 'Division'){
                      if(trimmedValue !== '' && trimmedValue !== 'null' && value !== undefined) {
                      return true
                    }else {
                      return this.createError({ message: 'Division field is required when Scope is Division' });
                    }}
              return true;
            },
          }),
          ContractNo: Yup.string().required('Contract Number is required'),
          NoOfLicenses: Yup.string(),
          LegalReviewBy: Yup.string().matches(nameExp, 'Invalid Name'),
          LegalReviewDate: Yup.string(),
          TotalContractValue: Yup.string(),
          CostPerSeats: Yup.string(), 
          ContractPDF: Yup.string()
      })
    ),
  });

  const isValueInOtherRows = (array, value, productName, vendorName, startDate,index) => {
    return array.some((item, i) => i !== index && formatDate(item.EndDate) === formatDate(value) && item.ProductName === productName && item.VendorName === vendorName && formatDate(item.StartDate) === formatDate(startDate));
  };

  // based on respective tables
  var existingVendorName = vendorData.map((row) => row.VendorName);

  // based on security assessment data
  var existingVendorName_contract = [...new Set(contractData.map((row) => row.VendorName))];
  existingVendorName_contract.sort((a, b) => a.localeCompare(b));

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const initialValues = {
    rows: [{ 
        id: Date.now(),
        action:'update', //by default
        VendorName:'',
        ProductName: '',
        StartDate : '',
        EndDate : '',
        Scope : '',
        Division: '',
        ContractNo: '',
        // ContractType: '',
        NoOfLicenses: '',
        LegalReviewBy: '',
        LegalReviewDate: '',
        TotalContractValue: '',
        CostPerSeats: '', 
        ContractPDF: '',
        UserEmail: '',
        UpdatedTime: '',
    }],
  };

  const handleClearVendor = (index,setFieldValue) => (event) =>{
    const selectedName = event.target.value;
      setFieldValue(`rows[${index}].VendorName`, selectedName);
      setFieldValue(`rows[${index}].ProductName`, '');
      setFieldValue(`rows[${index}].StartDate`, '');
      setFieldValue(`rows[${index}].EndDate`, '');
      setFieldValue(`rows[${index}].Scope`, '');
      setFieldValue(`rows[${index}].Division`, '');
      setFieldValue(`rows[${index}].ContractNo`, '');
      setFieldValue(`rows[${index}].NoOfLicenses`, '');
      setFieldValue(`rows[${index}].LegalReviewBy`, '');
      setFieldValue(`rows[${index}].LegalReviewDate`, '');
      setFieldValue(`rows[${index}].TotalContractValue`, '');
      setFieldValue(`rows[${index}].CostPerSeats`, '');
      setFieldValue(`rows[${index}].ContractPDF`, '');
      setFieldValue(`rows[${index}].UserEmail`, userInfo.email);
      setFieldValue(`rows[${index}].UpdatedTime`, new Date());
  }
  const handleClearProduct = (index,setFieldValue,vendor) => (event) =>{
    const selectedName = event.target.value;
     setFieldValue(`rows[${index}].VendorName`, vendor);
     setFieldValue(`rows[${index}].ProductName`, selectedName);
     setFieldValue(`rows[${index}].StartDate`, '');
     setFieldValue(`rows[${index}].EndDate`, '');
     setFieldValue(`rows[${index}].Scope`, '');
     setFieldValue(`rows[${index}].Division`, '');
     setFieldValue(`rows[${index}].ContractNo`, '');
     setFieldValue(`rows[${index}].NoOfLicenses`, '');
     setFieldValue(`rows[${index}].LegalReviewBy`, '');
     setFieldValue(`rows[${index}].LegalReviewDate`, '');
     setFieldValue(`rows[${index}].TotalContractValue`, '');
     setFieldValue(`rows[${index}].CostPerSeats`, '');
     setFieldValue(`rows[${index}].ContractPDF`, '');
      setFieldValue(`rows[${index}].UserEmail`, userInfo.email);
      setFieldValue(`rows[${index}].UpdatedTime`, new Date());
  }
  const handleClearStartDate = (index,setFieldValue,vendor,product) => (event) =>{
    const selectedName = event.target.value;
     setFieldValue(`rows[${index}].VendorName`, vendor);
     setFieldValue(`rows[${index}].ProductName`, product);
     setFieldValue(`rows[${index}].StartDate`, formatDate(selectedName));
     setFieldValue(`rows[${index}].EndDate`, '');
     setFieldValue(`rows[${index}].Scope`, '');
     setFieldValue(`rows[${index}].Division`, '');
     setFieldValue(`rows[${index}].ContractNo`, '');
     setFieldValue(`rows[${index}].NoOfLicenses`, '');
     setFieldValue(`rows[${index}].LegalReviewBy`, '');
     setFieldValue(`rows[${index}].LegalReviewDate`, '');
     setFieldValue(`rows[${index}].TotalContractValue`, '');
     setFieldValue(`rows[${index}].CostPerSeats`, '');
     setFieldValue(`rows[${index}].ContractPDF`, '');
      setFieldValue(`rows[${index}].UserEmail`, userInfo.email);
      setFieldValue(`rows[${index}].UpdatedTime`, new Date());
  }

  const handleNameChange = (index, setFieldValue,based_on,vn,pn,sd) => (event) => {
    const selectedName = event.target.value;
    var selectedRow;
    if (based_on === 'vendor'){
      selectedRow = contractData.find((row) => row.VendorName === selectedName);
    }else if(based_on === 'product'){
      selectedRow = contractData.find((row) => row.ProductName === selectedName);
    }else if(based_on === 'StartDate'){
      selectedRow = contractData.find((row) => row.VendorName === vn && row.ProductName === pn && formatDate(row.StartDate) === formatDate(selectedName));
      
    }else if(based_on === 'EndDate'){
      selectedRow = contractData.find((row) => row.VendorName === vn && row.ProductName === pn && formatDate(row.StartDate) === formatDate(sd) && formatDate(row.EndDate) === formatDate(selectedName));
    } 
    if (selectedRow) {
      setFieldValue(`rows[${index}].VendorName`, selectedRow.VendorName);
      setFieldValue(`rows[${index}].ProductName`, selectedRow.ProductName);
      setFieldValue(`rows[${index}].StartDate`, formatDate(selectedRow.StartDate));
      setFieldValue(`rows[${index}].EndDate`, formatDate(selectedRow.EndDate));
      setFieldValue(`rows[${index}].Scope`, selectedRow.Scope);
      setFieldValue(`rows[${index}].Division`, selectedRow.Division);
      setFieldValue(`rows[${index}].ContractNo`, selectedRow.ContractNo);
      setFieldValue(`rows[${index}].NoOfLicenses`, selectedRow.NoOfLicenses);
      setFieldValue(`rows[${index}].LegalReviewBy`, selectedRow.LegalReviewBy);
      setFieldValue(`rows[${index}].LegalReviewDate`, formatDate(selectedRow.LegalReviewDate));
      setFieldValue(`rows[${index}].TotalContractValue`, selectedRow.TotalContractValue);
      setFieldValue(`rows[${index}].CostPerSeats`, selectedRow.CostPerSeats);
      setFieldValue(`rows[${index}].ContractPDF`, selectedRow.ContractPDF);
      setFieldValue(`rows[${index}].UserEmail`, userInfo.email);
      setFieldValue(`rows[${index}].UpdatedTime`, new Date());
    }
    
  };
  const handleScopeChange = (index, setFieldValue, division) => (event) =>{
    const selectedName = event.target.value;
    event.target.value === 'Trimble' ? setFieldValue(`rows[${index}].Division`, '') : setFieldValue(`rows[${index}].Division`, division)
    setFieldValue(`rows[${index}].Scope`, selectedName)
   }

  const formatDate = date => {
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
    // return `${day}-${month}-${year}`;
  };


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [toastError, setToastError] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleErrorClick = (message) => {
    setErrorMessage(message)
    setToastError(true);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastError(false);
  };

  const [toastWarning, setToastWarning] = React.useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const handleWarningClick = (message) => {
    setWarningMessage(message);
    setToastWarning(true);
  };

  const handleWarningClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastWarning(false);
  };
  
  const handleInsertData = async(SubmittedContractData,resetForm) => {
    try {
      const response = await axios.post('https://software-procurement-catalog-dev.trimble.cloud/contractdetailsdata',{ data: SubmittedContractData });
      console.log('response',response.data);
      if (response.data === 'Data inserted successfully'){
        refreshApi();
        handleClick();
        resetForm();
        setLoading('no');
      }else{
        console.log('error')
        setLoading('no');
        handleErrorClick('Error on updating data in database')
      }
  }
    catch (error) {
        console.log("error is caught", error)
        setLoading('no');
        handleErrorClick('Error on updating data in database')
    }
  }

  // submit value check with existing value
  const remainingArray = (randomArray,originalArray) => {
    return randomArray.filter(randomItem => {
      return !originalArray.some(originalItem => 
        originalItem.VendorName === randomItem.VendorName &&
        originalItem.ProductName === randomItem.ProductName &&
        formatDate(originalItem.StartDate) === formatDate(randomItem.StartDate) && 
        formatDate(originalItem.EndDate) === formatDate(randomItem.EndDate) && 
        originalItem.Scope === randomItem.Scope &&
        originalItem.Division === randomItem.Division &&
        originalItem.ContractNo === randomItem.ContractNo &&
        originalItem.NoOfLicenses === randomItem.NoOfLicenses &&
        originalItem.LegalReviewBy === randomItem.LegalReviewBy &&
        formatDate(originalItem.LegalReviewDate) === formatDate(randomItem.LegalReviewDate) && 
        originalItem.TotalContractValue === randomItem.TotalContractValue &&
        originalItem.CostPerSeats === randomItem.CostPerSeats &&
        originalItem.ContractPDF === randomItem.ContractPDF
        
      );
    });
  }

  const checkForDuplicatesEntries = (data) => {
    const seen = new Set();
    const duplicates = [];
    for (const item of data) {
      if (item.action === 'create'){
      const key = `${item.action}-${item.VendorName}-${item.ProductName}-${item.StartDate}-${item.EndDate}`;
      if (seen.has(key)) {
        duplicates.push(item);
      } else {
        seen.add(key);
      }
    }
    }
    return duplicates
  };

  // update or create action
  
  const handleSubmit = (values, { resetForm, setSubmitting }) => {

    if (values.rows.length === 0){
      handleWarningClick('Kindly Add Rows to update product details');
    }else {
      // console.log(values.rows);
      var remaingValue = remainingArray(values.rows,contractData)
      if (remaingValue.length === 0){
        handleWarningClick('There is no change in the Updated Products Data');
      }else{
        var duplicates = checkForDuplicatesEntries(remaingValue)
        if (duplicates.length > 0) {
          handleErrorClick('Duplicate contract data entered, Each contract data must be unique for new entries')
        } else {
          setLoading('yes');
          handleInsertData(remaingValue,resetForm);
        }
        
      }
    }
    setSubmitting(false)

    // console.log('value',values.rows)
  };

  const refreshApi = async event => {
    // await delay(10000)
    fetchData();
  }

  return (
    <>
    {/* {fetchData()} */}
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Updated product details successfully!
      </Alert>
    </Snackbar>
    <Snackbar open={toastError} autoHideDuration={4000} onClose={handleErrorClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
    <Snackbar open={toastWarning} autoHideDuration={4000} onClose={handleWarningClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleWarningClose} severity="warning" sx={{ width: '100%' }}>
        {warningMessage}
      </Alert>
    </Snackbar>
    {loading === 'yes'? 
      <div className="text-center" style={{paddingTop: String(screenHeight/3).concat('px')}}>
        <Spinner animation="border" variant="primary" role="status" />
        <div className="h3 text-primary mt-2">Updating Security Assessment Details...</div>
      </div>
      : 
      <div style={{backgroundColor:'#f1f1f6'}}>
      <div className='display-3' style={{color:'#004f83', textAlign:'center',padding:'15px 0px 0px 0px'}}>Create/Update Contracts</div>  
     <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ values, touched, errors, setFieldValue, handleChange, isSubmitting, setSubmitting }) => (
        <Form style={{padding:'18px'}}>
            {isSubmitting && Object.keys(errors).length > 0 && (
              handleErrorClick('Kindly enter required details!')
              // console.log(errors)
            )}
          <Paper className={style.tableContainer} elevation={2} style={{height:String(screenHeight-220).concat('px')}}>
          <table className={style.table}>
            <thead style={{color: "#004F83", textAlign:'center'}}>
              <tr>
                <th>Vendor Name <span style={{fontSize:'10px'}}> *</span></th>
                <th>Product Name <span style={{fontSize:'10px'}}> *</span></th>
                <th>Start Date <span style={{fontSize:'10px'}}> *</span></th>
                <th style={{borderRight: '1.5px solid grey'}}>End Date <span style={{fontSize:'10px'}}> *</span></th>
                <th>Scope <span style={{fontSize:'10px'}}> *</span></th>
                <th>Division</th>
                <th>Contract No. <span style={{fontSize:'10px'}}> *</span></th>
                {/* <th>Contract Type</th> */}
                <th>No. of Licenses</th>
                <th>LegalReviewBy</th>
                <th>LegalReviewDate</th>
                <th>TotalContractValue</th>
                <th>CostPerSeats</th>
                <th>ContractPDF</th>
                <th></th>
              </tr>
            </thead><FieldArray name="rows">
            
              
                {({ insert, remove, push }) => (
                  <>
                   <tbody>
                    {values.rows.map((row, index) => (
                      <tr key={row.id}>
                        <td>                          
                          <OverlayTrigger
                          key="overlay1"
                          placement="top"
                          overlay={<Tooltip id="btntooltip">{row.VendorName === '' ? 'Select VendorName' : row.VendorName}</Tooltip>}
                          >
                          {row.action === 'update' ? 
                          <Field
                          as="select"
                          name={`rows[${index}].VendorName`}
                          onChange={handleClearVendor(index,setFieldValue)}
                          style={{border:
                            errors.rows?.[index]?.VendorName && touched.rows?.[index]?.VendorName
                              ? '1px solid red'
                              : '1px solid transparent', 
                            backgroundColor:'transparent', width:'250px',
                          height:'30px'

                          }}
                        >
                          <option value="" style={{padding:"0.4rem 1rem"}}>Select VendorName</option>
                          {/* update */}
                          {existingVendorName_contract.map((VendorName) => (
                               <option 
                               key={VendorName} 
                               value={VendorName}
                              //  disabled={isValueInOtherRows(values.rows, VendorName, index)}
                               >
                                 {VendorName}
                               </option>
                             
                           ))}
                        </Field> :
                        <Field
                        as="select"
                        name={`rows[${index}].VendorName`}
                        style={{border:
                          errors.rows?.[index]?.VendorName && touched.rows?.[index]?.VendorName
                            ? '1px solid red'
                            : '1px solid transparent', 
                          backgroundColor:'transparent', width:'250px',
                        height:'30px'

                        }}
                      >
                        <option value="" style={{padding:"0.4rem 1rem"}}>Select VendorName</option>
                        {/* create */}
                        {existingVendorName.map((VendorName) => (
                             <option 
                             key={VendorName} 
                             value={VendorName}
                            //  disabled={isValueInOtherRows(values.rows, VendorName, index)}
                             >
                               {VendorName}
                             </option>
                           
                         ))}
                      </Field>
                        }
                          </OverlayTrigger>
                          {errors.rows?.[index]?.VendorName && touched.rows?.[index]?.VendorName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].VendorName`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                        <OverlayTrigger
                          key="overlay4"
                          placement="top"
                          overlay={<Tooltip id="btntooltip">{row.ProductName === '' ? 'Select ProductName' : row.ProductName}</Tooltip>}
                          >
                          {row.action === 'update' ?
                          <Field
                          as="select"
                          name={`rows[${index}].ProductName`}
                          onChange={handleClearProduct(index,setFieldValue,row.VendorName)}
                          style={{
                            border: 
                            errors.rows?.[index]?.ProductName && touched.rows?.[index]?.ProductName
                              ? '1px solid red'
                              : '1px solid transparent', 
                            backgroundColor:'transparent', width:'200px',height:'30px'
                                
                          }}
                          // disabled={!values.rows[index].VendorName}
                        >
                        <option value="">Select ProductName</option>
                        {/* update */}
                        {[...new Set(contractData.filter(item => item.VendorName.toLowerCase() === row.VendorName.toLowerCase()).map(item => item.ProductName))]
                        .map((ProductName) => (
                              <option 
                              key={ProductName} 
                              value={ProductName}
                              // disabled={ProductName === 'null'}
                              >
                                {ProductName}
                              </option>
                            ))}                    
                        </Field>
                        :
                        <Field
                            as="select"
                            name={`rows[${index}].ProductName`}
                            style={{
                              border: 
                              errors.rows?.[index]?.ProductName && touched.rows?.[index]?.ProductName
                              ? '1px solid red'
                              : '1px solid transparent', 
                              backgroundColor:'transparent', width:'200px',height:'30px'
                                  
                            }}
                            disabled={!values.rows[index].VendorName}
                          >
                          <option value="">Select ProductName</option>
                          {vendorProductData.VendorName.find((vendor) => vendor.name.toLowerCase() === row.VendorName.toLowerCase()) ? 
                          vendorProductData.VendorName.filter(vendor=>vendor.name.toLowerCase().includes(row.VendorName.toLowerCase()))[0].ProductName.map((ProductName)=>
                            
                          <option 
                            key={ProductName.name} 
                            value={ProductName.name}
                            // disabled={ProductName.name === 'null'}
                          >
                            {ProductName.name}
                          </option>
                          )
                          : ''}
                          </Field>
                          }
                          </OverlayTrigger>
                          {errors.rows?.[index]?.ProductName && touched.rows?.[index]?.ProductName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].ProductName`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          {row.action === 'update' ? 
                          <Field
                          as="select"
                          // type="date"
                          name={`rows[${index}].StartDate`}
                          onChange={handleClearStartDate(index,setFieldValue,row.VendorName,row.ProductName)}
                          style={{
                            border:
                                errors.rows?.[index]?.StartDate && touched.rows?.[index]?.StartDate
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                                
                          }}
                          // disabled={!values.rows[index].VendorName}
                        >
                        <option value="">Select Start Date</option>
                        {/* update */}
                        {[...new Set(contractData.filter(item => item.VendorName.toLowerCase() === row.VendorName.toLowerCase() && item.ProductName.toLowerCase() === row.ProductName.toLowerCase()).map(item => item.StartDate))]
                        .map((StartDate) => (
                              <option 
                              key={StartDate} 
                              value={formatDate(StartDate)}
                              // disabled={isValueInOtherRows(values.rows, AssessmentDate, row.ProductName, row.VendorName,index)}
                              >
                                {formatDate(StartDate)}
                              </option>
                            ))}</Field>
                            :
                            <Field
                            type="date"
                            name={`rows[${index}].StartDate`}
                            style={{border:
                                errors.rows?.[index]?.StartDate && touched.rows?.[index]?.StartDate
                                  ? '1px solid red'
                                  : '1px solid transparent', 
                                backgroundColor:'transparent', width:'200px',
                              height:'30px'
                              }}
                            max={formatDate(new Date(row.EndDate).setDate(new Date(row.EndDate).getDate() - 1))}
                            disabled={!values.rows[index].VendorName}
                          />
                          }
                          {errors.rows?.[index]?.StartDate && touched.rows?.[index]?.StartDate && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].StartDate`} component="div" />
                            </span>
                          )}
                        </td>
                        <td style={{borderRight: '1.5px solid grey'}}>
                          {row.action === 'update' ? 
                          <Field
                          as="select"
                          // type="date"
                          name={`rows[${index}].EndDate`}
                          onChange={handleNameChange(index, setFieldValue,'EndDate',row.VendorName,row.ProductName,row.StartDate)}
                          style={{
                            border:
                                errors.rows?.[index]?.EndDate && touched.rows?.[index]?.EndDate
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                                
                          }}
                          // disabled={!values.rows[index].VendorName}
                        >
                        <option value="">Select End Date</option>
                        {/* update */}
                        {[...new Set(contractData.filter(item => item.VendorName.toLowerCase() === row.VendorName.toLowerCase() && item.ProductName.toLowerCase() === row.ProductName.toLowerCase()  && formatDate(item.StartDate) === formatDate(row.StartDate)).map(item => item.EndDate))]
                        .map((EndDate) => (
                              <option 
                              key={EndDate} 
                              value={formatDate(EndDate)}
                              disabled={isValueInOtherRows(values.rows, EndDate, row.ProductName, row.VendorName, row.StartDate,index)}
                              >
                                {formatDate(EndDate)}
                              </option>
                            ))}</Field>
                            :
                            <Field
                            type="date"
                            name={`rows[${index}].EndDate`}
                            style={{border:
                                errors.rows?.[index]?.EndDate && touched.rows?.[index]?.EndDate
                                  ? '1px solid red'
                                  : '1px solid transparent', 
                                backgroundColor:'transparent', width:'200px',
                              height:'30px'
                              }}
                            min={formatDate(new Date(row.StartDate).setDate(new Date(row.StartDate).getDate() + 1))}
                            disabled={!values.rows[index].VendorName}
                          />
                          }
                          {errors.rows?.[index]?.EndDate && touched.rows?.[index]?.EndDate && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].EndDate`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            as="select"
                            name={`rows[${index}].Scope`}
                            onChange={handleScopeChange(index, setFieldValue, row.Division)}
                            disabled={!values.rows[index].VendorName}
                            style={{
                              border:
                                errors.rows?.[index]?.Scope && touched.rows?.[index]?.Scope
                                  ? '1px solid red'
                                  : '1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                          >
                            <option value="">Select Scope</option>
                            <option value="Trimble">Trimble</option>
                            <option value="Division">Division</option>
                          </Field>
                          {errors.rows?.[index]?.Scope && touched.rows?.[index]?.Scope && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].Scope`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type="text"
                            name={`rows[${index}].Division`}
                            placeholder='Enter Division'
                            style={{
                              border:errors.rows?.[index]?.Division && touched.rows?.[index]?.Division
                              ? '1px solid red'
                              : '1px solid transparent', 
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            disabled={!values.rows[index].VendorName || row.Scope !== 'Division'}
                          />
                          {errors.rows?.[index]?.Division && touched.rows?.[index]?.Division && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].Division`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type="text"
                            name={`rows[${index}].ContractNo`}
                            placeholder='Enter Contract Number'
                            style={{
                              border:
                              errors.rows?.[index]?.ContractNo && touched.rows?.[index]?.ContractNo
                              ? '1px solid red'
                              : '1px solid transparent', 
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            maxlength="20"
                            disabled={!values.rows[index].VendorName}
                          />
                          {errors.rows?.[index]?.ContractNo && touched.rows?.[index]?.ContractNo && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].ContractNo`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                          <Field
                            type="number"
                            max="1000000"
                            min='0'
                            name={`rows[${index}].NoOfLicenses`}
                            placeholder='Enter No. Of Licenses'
                            style={{
                              border:'1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            disabled={!values.rows[index].VendorName}
                          />
                        </td>
                        <td>
                          <Field
                            type="text"
                            name={`rows[${index}].LegalReviewBy`}
                            placeholder='Enter Legal Reviewer Name'
                            style={{
                              border:
                              errors.rows?.[index]?.LegalReviewBy && touched.rows?.[index]?.LegalReviewBy
                              ? '1px solid red'
                              : '1px solid transparent', 
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            disabled={!values.rows[index].VendorName}
                          />
                          {errors.rows?.[index]?.LegalReviewBy && touched.rows?.[index]?.LegalReviewBy && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].LegalReviewBy`} component="div" />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
                            type="date"
                            name={`rows[${index}].LegalReviewDate`}
                            style={{border:'1px solid transparent', 
                                backgroundColor:'transparent', width:'200px',
                              height:'30px'
                              }}
                            min='2000-01-01'
                            disabled={!values.rows[index].VendorName}
                           />
                        </td>
                        <td>
                          <Field
                            type="number"
                            max="1000000000"
                            step="any"
                            min='0'
                            name={`rows[${index}].TotalContractValue`}
                            placeholder='Enter Total Contract Cost'
                            style={{
                              border:'1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            disabled={!values.rows[index].VendorName}
                          />
                        </td>
                        <td>
                          <Field
                            type="number"
                            max="1000000000"
                            step="any"
                            min='0'
                            name={`rows[${index}].CostPerSeats`}
                            placeholder='Enter Cost Per Seats Amount'
                            style={{
                              border:'1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            disabled={!values.rows[index].VendorName}
                          />
                        </td>
                        <td>
                          <Field
                            type="text"
                            name={`rows[${index}].ContractPDF`}
                            placeholder='Enter Contract PDF Link'
                            style={{
                              border:'1px solid transparent',
                                  backgroundColor:'transparent', width:'200px',height:'30px'
                            }}
                            disabled={!values.rows[index].VendorName}
                          />
                        </td>
                        
                        <td>

                          <Button
                          variant="text-danger"
                          size="sm" 
                          className="mr-2" 
                          type='button' 
                          onClick={() => {
                            remove(index);
                            // setExistingNames(existingNames.concat(row.ProductName))
                          }} 
                          style={{padding:'7px 12px', fontSize:'15px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="mi-solid mi-delete" viewBox="0 0 24 24">
                              <path d="M18 4h-2.09c-.27 0-.52-.11-.71-.29h.01l-.41-.41a.99.99 0 0 0-.71-.29H9.92c-.27 0-.52.11-.71.29l-.41.41a.99.99 0 0 1-.71.29H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1ZM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12Zm8-9.49c0-.28.22-.5.5-.5s.5.22.5.5v9c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-9Zm-5 0c0-.28.22-.5.5-.5s.5.22.5.5v9c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-9Z"/>
                            </svg>
                          </Button>
                        </td>
                      </tr>
                    ))}
                    <br/>
                    </tbody>
                    </>
                )}
              </FieldArray>
          </table>
          <OverlayTrigger
            key="overlay2"
            placement="top"
            overlay={<Tooltip id="btntooltip">Create a New Contract</Tooltip>}
          >
          <Fab
            style={{backgroundColor:'#2A7DB2'}}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(18),
              right: (theme) => theme.spacing(2),
            }}
            onClick={() => setFieldValue('rows', [...values.rows, 
              { 
                action:'create',
                VendorName:'',
                ProductName: '',
                StartDate : '',
                EndDate : '',
                Scope : '',
                Division: '',
                ContractNo: '',
                // ContractType: '',
                NoOfLicenses: '',
                LegalReviewBy: '',
                LegalReviewDate: '',
                TotalContractValue: '',
                CostPerSeats: '', 
                ContractPDF: '',
                UserEmail: userInfo.email,
                UpdatedTime: new Date(), }])}
          >
            <CreateIcon style={{color:'white'}} />
          </Fab>
          </OverlayTrigger>
          <OverlayTrigger
            key="overlay3"
            placement="top"
            overlay={<Tooltip id="btntooltip">Update Existing Contract</Tooltip>}
          >
          <Fab
            style={{backgroundColor:'#2A7DB2'}}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(10),
              right: (theme) => theme.spacing(2),
            }}
            onClick={() => setFieldValue('rows', [...values.rows, 
              { action:'update',
                VendorName:'',
                ProductName: '',
                StartDate : '',
                EndDate : '',
                Scope : '',
                Division: '',
                ContractNo: '',
                // ContractType: '',
                NoOfLicenses: '',
                LegalReviewBy: '',
                LegalReviewDate: '',
                TotalContractValue: '',
                CostPerSeats: '', 
                ContractPDF: '',
                UserEmail: '',
                UpdatedTime: '', }])}
          >
            <UpdateIcon style={{color:'white'}} />
          </Fab>
          </OverlayTrigger>
         </Paper>
         <br/>
         <div className={style.buttonContainer}>
          <Button 
            variant="primary"
            size="sm" 
            className="mr-2 center-button" 
            type='submit' 
            style={{padding:'7px 12px', fontSize:'15px'}}
             >
            Submit
          </Button>
          </div>
        </Form>
      )}
    </Formik>
    </div>
    }
    </>
  );
};

export default Contract;