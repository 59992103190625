import { Divider } from "@mui/material";
import { Card } from "@trimbleinc/modus-react-bootstrap";
import React from "react" 

function UnderProcess() {
    
  return (
    <div >
      <center>
      <Card style={{ width: "25rem" }}>
        <Card.Img variant="top" src="https://media.istockphoto.com/id/1283050796/vector/flat-design-under-construction-concept.jpg?s=612x612&w=0&k=20&c=CATQe8sEl7YdpwxZ4VHwYh5FjHY9MkbyRNhALyslZwA=" alt="" />
        <Card.Body>
            <Divider/>
            <Card.Text>
            Website is under construction
            </Card.Text>
        </Card.Body>
    </Card>
      </center>
    </div>
  )
}


export default UnderProcess