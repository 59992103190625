import React, {useState, useEffect} from 'react';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Paper from '@mui/material/Paper';
import { Button, OverlayTrigger, Tooltip, Spinner } from '@trimbleinc/modus-react-bootstrap';
import { useOktaAuth } from '@okta/okta-react';
import style from './page.module.css';
import Fab from '@mui/material/Fab';
import CreateIcon from '@mui/icons-material/Create';
import UpdateIcon from '@mui/icons-material/Update';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';



const VendorUpdate = () => {
	const [loading, setLoading] = useState('no');											 
    const [userInfo, setUserInfo] = useState({name:"",email:""});
    const { authState, oktaAuth } = useOktaAuth();
    useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
      //   setUserInfo(null);
        setUserInfo({name:null,email:null});
      } else {
        // setUserInfo(authState.idToken.claims);\
        oktaAuth.getUser().then((info) => {
          setUserInfo(info);
        });
      }
    }, [authState, oktaAuth]);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    useEffect(() => {
      const handleResize = () => {
        setScreenHeight(window.innerHeight);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const [vendorContactsDetail, setvendorContactData] = useState([])
    var existingVendorNames = vendorContactsDetail.map((row) => row.VendorName);
    const getApiData = async (api,setData) => {
        const response = await fetch(
          api
        ).then((response) => response.json());
      // console.log('hahaha',response)
      setData(response);
      };
    
	  
	  const fetchData = () => { getApiData("https://software-procurement-catalog-dev.trimble.cloud/vendorContactData",setvendorContactData);}
      useEffect(() => {
    
        fetchData();
      }, []);
	  
      // console.log(vendorContactsDetail, 'dd')
      const initialValues = {
        rows: [{ 
            id: Date.now(),
            action:'update',
            VendorName: '',
            Description: '',
            AcctManagerName: '',
            AcctManagerEmail: '',
            AcctManagerPhone: '',
            TechManagerName: '',
            TechManagerEmail: '',
            TechManagerPhone: '',
            TrimbleEnablementManagerName: '',
            TrimbleEnablementManagerEmail: '',
            TrimbleEnablementManagerPhone: '',
            UserEmail: '',
            UpdatedTime: '',
        }],
      };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    const [open, setOpen] = React.useState(false);
      const handleClick = () => {
        setOpen(true);
      };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
    
      const [toastError, setToastError] = React.useState(false);
      const [errorMessage, setErrorMessage] = useState('');
      const handleErrorClick = (message) => {
        setErrorMessage(message)
        setToastError(true);
      };
    
      const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setToastError(false);
      };
    
      const [toastWarning, setToastWarning] = React.useState(false);
      const [warningMessage, setWarningMessage] = useState('');
      const handleWarningClick = (message) => {
        setWarningMessage(message);
        setToastWarning(true);
      };
    
      const handleWarningClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setToastWarning(false);
      };
	  function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      const refreshApi = async event => {
       // await sleep(2000)
        fetchData();
      }				  

      const handleNameChange = (index, setFieldValue) => (event) => {
        const selectedName = event.target.value;
        const selectedRow = vendorContactsDetail.find((row) => row.VendorName === selectedName);
        if (selectedRow) { 
          setFieldValue(`rows[${index}].VendorName`, selectedRow.VendorName);
          setFieldValue(`rows[${index}].Description`, selectedRow.Description);
          setFieldValue(`rows[${index}].AcctManagerName`, selectedRow.AcctManagerName);
          setFieldValue(`rows[${index}].AcctManagerEmail`, selectedRow.AcctManagerEmail);
          setFieldValue(`rows[${index}].AcctManagerPhone`, selectedRow.AcctManagerPhone);
          setFieldValue(`rows[${index}].TechManagerName`, selectedRow.TechManagerName);
          setFieldValue(`rows[${index}].TechManagerEmail`, selectedRow.TechManagerEmail);
          setFieldValue(`rows[${index}].TechManagerPhone`, selectedRow.TechManagerPhone);
          setFieldValue(`rows[${index}].TrimbleEnablementManagerName`, selectedRow.TrimbleEnablementManagerName);
          setFieldValue(`rows[${index}].TrimbleEnablementManagerEmail`, selectedRow.TrimbleEnablementManagerEmail);
          setFieldValue(`rows[${index}].TrimbleEnablementManagerPhone`, selectedRow.TrimbleEnablementManagerPhone);
          setFieldValue(`rows[${index}].UserEmail`, userInfo.email);
          setFieldValue(`rows[${index}].UpdatedTime`, new Date());
          
          
        }
        
      };
      const isValueInOtherRows = (array, value, index) => {
        return array.some((item, i) => i !== index && item.VendorName === value);
      };

      const checkForDuplicatesEntries = (data) => {
        const seen = new Set();
        const duplicates = [];
        for (const item of data) {
          if (item.action === 'create'){
          const key = `${item.action}-${item.VendorName}`;
          if (seen.has(key)) {
            duplicates.push(item);
          } else {
            seen.add(key);
          }
        }
        }
        return duplicates
      };

      const handleSubmit = (values, { resetForm, setSubmitting }) => {

        if (values.rows.length === 0){
          handleWarningClick('Kindly Add Rows to update Vendor details');
        }else {
          console.log(values.rows);
          var remaingValue = remainingArrays(values.rows,vendorContactsDetail)
          var updatedDataToInsert = remainingArray(values.rows,vendorContactsDetail)
          if (remaingValue.length === 0){
            handleWarningClick('There is no change in the Vendor Data');
          }else{
            var duplicates = checkForDuplicatesEntries(remaingValue)
            if (duplicates.length > 0) {
              handleErrorClick('Duplicate vendor name entered, Each vendor name must be unique for new entries')
            } else {
              setLoading('yes');
              handleInsertData(updatedDataToInsert,resetForm);
            }
           // handleClick()
           // resetForm();
          }
        }
        setSubmitting(false)
      };


      const handleInsertData = async(vendorUpdatedContactData, resetForm) => {
        try {
            console.log('contact data', vendorUpdatedContactData)
          const response = await axios.post('https://software-procurement-catalog-dev.trimble.cloud/vendorUpdatedContactData',{ data: vendorUpdatedContactData });
            
         console.log('response data ',response);
           if (response.data === 'Data inserted successfully' ) {
             refreshApi();
			 handleClick();
			 resetForm();
            setLoading('no');
           }
           else{
             console.log('error')
             handleErrorClick('Error on updating data in database')
             setLoading('no');
             console.log('something wrong')
           }
      }
        catch (error) {
            console.log("error is caught", error)
            setLoading('no');
            console.log('errror in ')
        }
      }

      const remainingArrays = (randomArray,originalArray) => {
        return randomArray.filter(randomItem => {
          return !originalArray.some(originalItem => 
            originalItem.VendorName.trim().toLowerCase() === randomItem.VendorName.trim().toLowerCase() &&
            originalItem.Description.trim().toLowerCase() === randomItem.Description.trim().toLowerCase() &&
            originalItem.AcctManagerName.trim().toLowerCase() === randomItem.AcctManagerName.trim().toLowerCase() &&
            originalItem.AcctManagerEmail.trim().toLowerCase() === randomItem.AcctManagerEmail.trim().toLowerCase() &&
            originalItem.AcctManagerPhone.trim() === randomItem.AcctManagerPhone.trim() &&
            originalItem.TechManagerName.trim().toLowerCase() === randomItem.TechManagerName.trim().toLowerCase() &&
            originalItem.TechManagerEmail.trim().toLowerCase() === randomItem.TechManagerEmail.trim().toLowerCase() &&
            originalItem.TechManagerPhone.trim() === randomItem.TechManagerPhone.trim() &&
            originalItem.TrimbleEnablementManagerName.trim().toLowerCase() === randomItem.TrimbleEnablementManagerName.trim().toLowerCase() &&
            originalItem.TrimbleEnablementManagerEmail.trim().toLowerCase() === randomItem.TrimbleEnablementManagerEmail.trim().toLowerCase() &&
            originalItem.TrimbleEnablementManagerPhone.trim() === randomItem.TrimbleEnablementManagerPhone.trim()

          );
        });
      }

      const remainingArray = (randomArray, originalArray) => {
        return randomArray.map(randomItem => {
          const matchingItem = originalArray.find(
            originalItem => originalItem.VendorName.trim().toLowerCase() === randomItem.VendorName.trim().toLowerCase()
          );
      
          if (matchingItem) { 
            const modifiedItem = { ...randomItem };
            if ((matchingItem.AcctManagerName.trim().toLowerCase() === randomItem.AcctManagerName.trim().toLowerCase()) || (randomItem.AcctManagerName.trim().toLowerCase() === 'null')) {
              modifiedItem.AcctManagerName = null;
            }
            if (matchingItem.Description.trim().toLowerCase() === randomItem.Description.trim().toLowerCase()) {
                modifiedItem.Description = null;
              }
            if (matchingItem.AcctManagerEmail.trim().toLowerCase() === randomItem.AcctManagerEmail.trim().toLowerCase()) {
              modifiedItem.AcctManagerEmail = null;
            }
            if (matchingItem.AcctManagerPhone.trim() === randomItem.AcctManagerPhone.trim()) {
              modifiedItem.AcctManagerPhone = null;
            }
            if ((matchingItem.TechManagerName.trim().toLowerCase() === randomItem.TechManagerName.trim().toLowerCase()) || (randomItem.TechManagerName.trim().toLowerCase() === 'null' )) {
                modifiedItem.TechManagerName = null;
              }
            if (matchingItem.TechManagerEmail.trim().toLowerCase() === randomItem.TechManagerEmail.trim().toLowerCase()) {
                modifiedItem.TechManagerEmail = null;
              }
            if (matchingItem.TechManagerPhone.trim() === randomItem.TechManagerPhone.trim()) {
                modifiedItem.TechManagerPhone = null;
              }
            if ((matchingItem.TrimbleEnablementManagerName.trim().toLowerCase() === randomItem.TrimbleEnablementManagerName.trim().toLowerCase()) || (randomItem.TrimbleEnablementManagerName.trim().toLowerCase() === 'null'))   {
              modifiedItem.TrimbleEnablementManagerName = null;
            }
            if (matchingItem.TrimbleEnablementManagerEmail.trim().toLowerCase() === randomItem.TrimbleEnablementManagerEmail.trim().toLowerCase()) {
              modifiedItem.TrimbleEnablementManagerEmail = null;
            }
            if (matchingItem.TrimbleEnablementManagerPhone.trim() === randomItem.TrimbleEnablementManagerPhone.trim()) {
              modifiedItem.TrimbleEnablementManagerPhone= null;
            }
      
            return modifiedItem;
          } else {
            return randomItem;
          }
        });
      };
      

      //const phoneRegExp = /^(?:\(\+\d+\)|\+\d+|\(\d+\)|\d+)[\d\s-]{6,17}\d$/ ///^(?:\(\+\d+\)|\+\d+|\(\d+\))[\d\s-]{6,17}\d$/
      //const emailRegExp = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[cC][oO][mM]|null|Null|NULL)$/
      
	  const phoneRegExp =  /^[0-9+()\s-]{8,25}$/      // /^(?:\(\+\d+\)|\+\d+|\(\d+\)|\d+)[\d\s-]{6,17}\d$/ ///^(?:\(\+\d+\)|\+\d+|\(\d+\))[\d\s-]{6,17}\d$/
      const emailRegExp =/^(?!.*\bnull\b)[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[cC][oO][mM]$/i
      const nameExp = /^(?![Nn][Uu][Ll][Ll]$)(?=.*[A-Za-z])['A-Za-z ]{3,50}$/ ;
	  
	  const validationSchema = Yup.object().shape({
        rows: Yup.array().of(
          Yup.object().shape({
              action: Yup.string().required('Action is required'),
              VendorName : Yup.string().test({
                name: 'conditional-validation',
                message: 'Vendor Name already exists',
                test: function(value) {
                  const actionValue = this.parent.action;  // Access the value of 'action' field
                  const trimmedValue = value.trim().toLowerCase();
                  
                  if (actionValue === 'create') {
                    return !existingVendorNames.map(item => item.toLowerCase()).includes(trimmedValue);
                  }
                  return !!trimmedValue;
                }
              }).required('Vendor Name is required'),
              Description : Yup.string(),
              AcctManagerName : Yup.string().test('name-validation', function (value) {
					if (this.parent.action === 'update') {
					  const matchingItem = vendorContactsDetail.find(
						originalItem => originalItem.VendorName.trim().toLowerCase() === this.parent.VendorName.trim().toLowerCase()
					  );
					if (matchingItem.AcctManagerName !== value) {
					  return nameExp.test(value) || this.createError({ message: 'Invalid Name' });
					}
					return true; // Return true if value is null or empty
				  } else { 
					return nameExp.test(value) || this.createError({ message: 'Invalid Name' });}
            }).required('Name is required'),
              AcctManagerEmail :Yup.string().test('conditional-validation', function (value) {
					if (this.parent.action === 'update') {
					  const matchingItem = vendorContactsDetail.find(
						originalItem => originalItem.VendorName.trim().toLowerCase() === this.parent.VendorName.trim().toLowerCase()
					  );
					if (matchingItem.AcctManagerEmail !== value) {
					  return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });
					}
					return true; // Return true if value is null or empty
				  } else { 
					return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });}
            }).required('Email is required'),
              AcctManagerPhone : Yup.string().matches(phoneRegExp, 'Invalid Phone Number'),
              TechManagerName : Yup.string().test('name-validation', function (value) {
					if (this.parent.action === 'update') {
					  const matchingItem = vendorContactsDetail.find(
						originalItem => originalItem.VendorName.trim().toLowerCase() === this.parent.VendorName.trim().toLowerCase()
					  );
					if (matchingItem.TechManagerName !== value) {
					  return nameExp.test(value) || this.createError({ message: 'Invalid Name' });
					}
					return true; // Return true if value is null or empty
				  } else { 
					return nameExp.test(value) || this.createError({ message: 'Invalid Name' });}
            }).required('Name is required'),
              TechManagerEmail :Yup.string().test('conditional-validation', function (value) {
               
					if (this.parent.action === 'update') {
					  const matchingItem = vendorContactsDetail.find(
						originalItem => originalItem.VendorName.trim().toLowerCase() === this.parent.VendorName.trim().toLowerCase()
					  );
					if (matchingItem.TechManagerEmail !== value) {
					  return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });
					}
					return true; // Return true if value is null or empty
				  } else { 
					return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });}
            }).required('Email is required'),
              TechManagerPhone : Yup.string().matches(phoneRegExp, 'Invalid Phone Number'),
              TrimbleEnablementManagerName : Yup.string().test('conditional-validation', function (value) {
						if (this.parent.action === 'update') {
						  const matchingItem = vendorContactsDetail.find(
							originalItem => originalItem.VendorName.trim().toLowerCase() === this.parent.VendorName.trim().toLowerCase()
						  );
						if (matchingItem.TrimbleEnablementManagerName !== value) {
						  return nameExp.test(value) || this.createError({ message: 'Invalid Name' });
						}
						return true; // Return true if value is null or empty
					  } else { 
						return nameExp.test(value) || this.createError({ message: 'Invalid Name' });}
					}).required('Name is required'),
              TrimbleEnablementManagerEmail : Yup.string().test('conditional-validation', function (value) {
              
						if (this.parent.action === 'update') {
						  const matchingItem = vendorContactsDetail.find(
							originalItem => originalItem.VendorName.trim().toLowerCase() === this.parent.VendorName.trim().toLowerCase()
						  );
						if (matchingItem.TrimbleEnablementManagerEmail !== value) {
						  return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });
						}
						return true; // Return true if value is null or empty
					  } else { 
						return emailRegExp.test(value) || this.createError({ message: 'Invalid email' });}
            }).required('Email is required'),
              TrimbleEnablementManagerPhone : Yup.string().matches(phoneRegExp, 'Invalid Phone Number'),
              
          })
        ),
      });

    return (
        <> 
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Updated Vendor details successfully!
      </Alert>
    </Snackbar>
    <Snackbar open={toastError} autoHideDuration={4000} onClose={handleErrorClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
      </Alert>
    </Snackbar>
    <Snackbar open={toastWarning} autoHideDuration={4000} onClose={handleWarningClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
      <Alert onClose={handleWarningClose} severity="warning" sx={{ width: '100%' }}>
        {warningMessage}
      </Alert>
    </Snackbar>
	{loading === 'yes' ? 
    // <div>hhh</div>
      <div className="text-center" style={{paddingTop: String(screenHeight/3).concat('px')}}>
        <Spinner animation="border" variant="primary" role="status" />
        <div className="h3 text-primary mt-2">Updating Vendor Details...</div>
      </div>  
      : 
    <div style={{backgroundColor:'#f1f1f6'}}>
    <div className='display-3' style={{color:'#004f83', textAlign:'center',padding:'15px 0px 0px 0px'}}>Create/Update Vendors</div> 
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
    {({ values, touched, errors, setFieldValue, handleChange, isSubmitting }) => (
    <Form style={{padding:'15px'}}>
            {isSubmitting && Object.keys(errors).length > 0 && (
              handleErrorClick('Kindly enter required details!')
            )}
    <Paper className={style.tableContainer} elevation={2} style={{height:String(screenHeight-170).concat('px')}}>
            <table className={style.table}>
            <thead style={{color: "#004F83", textAlign:'center'}}>
              <tr>
                <th className={`${style.commonFirstBeforeHeaderData} ${style.commonFirstHeader}`}>Vendor Name <span style={{fontSize:'10px'}}> *</span></th>
                <th>Description<span style={{fontSize:'10px'}}></span></th>
                <th>Account Manager Name<span style={{fontSize:'10px'}}> *</span></th>
                <th>Account Manager Email<span style={{fontSize:'10px'}}> *</span></th>
                <th>Account Manager Phone</th>
                <th>Technical Manager Name<span style={{fontSize:'10px'}}> *</span></th>
                <th>Technical Manager Email<span style={{fontSize:'10px'}}> *</span></th>
                <th>Technical Manager Phone</th>
                <th>Trimble Enablement Manager Name<span style={{fontSize:'10px'}}> *</span></th>
                <th>Trimble Enablement Manager Email<span style={{fontSize:'10px'}}> *</span></th>
                <th>Trimble Enablement Manager Phone</th>
                <th></th>
              </tr>
            
            </thead>
            <FieldArray name="rows">
            {({ insert, remove, push }) => (
                <>
                <tbody>
                {values.rows.map((row, index) => (
                    <tr>
                        <td className={`${style.commonFirstHeaderData} ${style.commonFirstData} ${style.commonFirstBeforeHeaderData}`}>
                          {row.action === 'update' ?
                          <OverlayTrigger
                          key="overlay1"
                          placement="top"
                          overlay={<Tooltip id="btntooltip">{row.VendorName ? row.VendorName : 'Select Vendor'}</Tooltip>}
                          >
                            <Field
                            as="select"
                            name={`rows[${index}].VendorName`}
                            onChange={handleNameChange(index, setFieldValue)}
                            style={{border:
                              errors.rows?.[index]?.VendorName && touched.rows?.[index]?.VendorName
                                ? '1px solid red'
                                : '1px solid transparent', 
                              backgroundColor:'transparent', width:'250px'
                            }}
                          >
                            <option value="" style={{padding:"0.4rem 1rem"}}>Select Vendor</option>
                            {vendorContactsDetail.map((row) => (
                               <option 
                               key={row.VendorName} 
                               value={row.VendorName}
                               disabled={isValueInOtherRows(values.rows, row.VendorName, index)}
                               >
                                 {row.VendorName} 
                               </option>
                             ))}
                             
                          </Field>
                          </OverlayTrigger>
                          :
                          <Field
                          type="text"
                          name={`rows[${index}].VendorName`}
                          placeholder='Enter Vendor Name'
                          style={{
                            border: errors.rows?.[index]?.VendorName && touched.rows?.[index]?.VendorName ? '1px solid red' : '1px solid transparent',
                            backgroundColor: 'transparent',
                            width: '250px',
                            height:'30px'
                          }}
                        />
                          }
                        
                          {errors.rows?.[index]?.VendorName && touched.rows?.[index]?.VendorName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].VendorName`}  />
                            </span>
                          )}
                          </td>
                          <td>
                          <textarea
                          name={`rows[${index}].Description`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Vendor description'
                          value={row.Description}
                          onChange={handleChange}
                          style={{
                            border:'2px solid transparent',
                            backgroundColor:'transparent',
                            width:'300px',
                            height:'90px',
                          }}
                          >
                          </textarea>
                          </td>
                          <td>
                        
                        <Field
                        type='text'
                          name={`rows[${index}].AcctManagerName`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Account Manager Name'
                          value={row.AcctManagerName}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.AcctManagerName && touched.rows?.[index]?.AcctManagerName
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          
                          {errors.rows?.[index]?.AcctManagerName && touched.rows?.[index]?.AcctManagerName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].AcctManagerName`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
                        type="text"
                          name={`rows[${index}].AcctManagerEmail`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Account Manager Email'
                          value={row.AcctManagerEmail}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.AcctManagerEmail && touched.rows?.[index]?.AcctManagerEmail
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.AcctManagerEmail && touched.rows?.[index]?.AcctManagerEmail && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].AcctManagerEmail`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
						 type="tel"
                          name={`rows[${index}].AcctManagerPhone`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Account Manager Phone'
                          value={row.AcctManagerPhone}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.AcctManagerPhone && touched.rows?.[index]?.AcctManagerPhone
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.AcctManagerPhone && touched.rows?.[index]?.AcctManagerPhone && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].AcctManagerPhone`}  />
                            </span>
                          )}
                        </td>
                        <td>
						 
                        <Field
						  type="text"
                          name={`rows[${index}].TechManagerName`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Technical Manager Name'
                          value={row.TechManagerName}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.TechManagerName && touched.rows?.[index]?.TechManagerName
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
						  
                          {errors.rows?.[index]?.TechManagerName && touched.rows?.[index]?.TechManagerName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TechManagerName`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
						 type="text"
                          name={`rows[${index}].TechManagerEmail`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Technical Manager Email'
                          value={row.TechManagerEmail}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.TechManagerEmail && touched.rows?.[index]?.TechManagerEmail
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.TechManagerEmail && touched.rows?.[index]?.TechManagerEmail && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TechManagerEmail`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
						 type="tel"
                          name={`rows[${index}].TechManagerPhone`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Technical Manager Phone'
                          value={row.TechManagerPhone}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.TechManagerPhone && touched.rows?.[index]?.TechManagerPhone
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.TechManagerPhone && touched.rows?.[index]?.TechManagerPhone && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TechManagerPhone`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        
                        <Field
                        type='text'
                          name={`rows[${index}].TrimbleEnablementManagerName`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Enablement Manager Name'
                          value={row.TrimbleEnablementManagerName}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.TrimbleEnablementManagerName && touched.rows?.[index]?.TrimbleEnablementManagerName
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
						 
                          {errors.rows?.[index]?.TrimbleEnablementManagerName && touched.rows?.[index]?.TrimbleEnablementManagerName && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleEnablementManagerName`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
						  type="text"
                          name={`rows[${index}].TrimbleEnablementManagerEmail`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Enablement Manager Email'
                          value={row.TrimbleEnablementManagerEmail}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.TrimbleEnablementManagerEmail && touched.rows?.[index]?.TrimbleEnablementManagerEmail
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.TrimbleEnablementManagerEmail && touched.rows?.[index]?.TrimbleEnablementManagerEmail && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleEnablementManagerEmail`}  />
                            </span>
                          )}
                        </td>
                        <td>
                        <Field
						  type="tel"
                          name={`rows[${index}].TrimbleEnablementManagerPhone`}
                          disabled={!values.rows[index].VendorName}
                          placeholder='Enter Enablement Manager Phone'
                          value={row.TrimbleEnablementManagerPhone}
                          onChange={handleChange}
                          style={{
                            border:
                              errors.rows?.[index]?.TrimbleEnablementManagerPhone && touched.rows?.[index]?.TrimbleEnablementManagerPhone
                                ? '1px solid red'
                                : '1px solid transparent',
                                backgroundColor:'transparent', width:'200px',height:'30px'
                          }}
                          >
                          </Field>
                          {errors.rows?.[index]?.TrimbleEnablementManagerPhone && touched.rows?.[index]?.TrimbleEnablementManagerPhone && (
                            <span className={style.error}>
                              <ErrorMessage name={`rows[${index}].TrimbleEnablementManagerPhone`}  />
                            </span>
                          )}
                        </td>
                        <td>
                          <Button
                          variant="text-danger"
                          size="sm" 
                          className="mr-2" 
                          type='button' 
                          onClick={() => {
                            remove(index);console.log(row.VendorName);
                          }} 
                          style={{padding:'7px 12px', fontSize:'15px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="mi-solid mi-delete" viewBox="0 0 24 24">
                              <path d="M18 4h-2.09c-.27 0-.52-.11-.71-.29h.01l-.41-.41a.99.99 0 0 0-.71-.29H9.92c-.27 0-.52.11-.71.29l-.41.41a.99.99 0 0 1-.71.29H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1ZM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12Zm8-9.49c0-.28.22-.5.5-.5s.5.22.5.5v9c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-9Zm-5 0c0-.28.22-.5.5-.5s.5.22.5.5v9c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-9Z"/>
                            </svg>
                          </Button>
                        </td>
                    </tr>
                ))}
                <br/>
                </tbody>
                </>
                )}
            </FieldArray>
            </table>
            <OverlayTrigger
            key="overlay2"
            placement="top"
            overlay={<Tooltip id="btntooltip">Create a New Vendor</Tooltip>}
          >
          <Fab
            style={{backgroundColor:'#2A7DB2'}}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(18),
              right: (theme) => theme.spacing(2),
            }}
            onClick={() => setFieldValue('rows', [...values.rows, 
              { action:'create',
                VendorName: '',
                Description:'',
                AcctManagerName: '',
                AcctManagerEmail: '',
                AcctManagerPhone: '',
                TechManagerName: '',
                TechManagerEmail: '',
                TechManagerPhone: '',
                TrimbleEnablementManagerName: '',
                TrimbleEnablementManagerEmail: '',
                TrimbleEnablementManagerPhone: '',
                UserEmail: userInfo.email,
                UpdatedTime: new Date(), }])}
          >
            <CreateIcon style={{color:'white'}} />
          </Fab>
          </OverlayTrigger>
          <OverlayTrigger
            key="overlay3"
            placement="top"
            overlay={<Tooltip id="btntooltip">Update Existing Vendor</Tooltip>}
          >
            <Fab
            style={{backgroundColor:'#2A7DB2'}}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(10),
              right: (theme) => theme.spacing(1.7),
            }}
            onClick={() => setFieldValue('rows', [...values.rows, 
              { action:'update',
                VendorName: '',
                Description:'',
                AcctManagerName: '',
                AcctManagerEmail: '',
                AcctManagerPhone: '',
                TechManagerName: '',
                TechManagerEmail: '',
                TechManagerPhone: '',
                TrimbleEnablementManagerName: '',
                TrimbleEnablementManagerEmail: '',
                TrimbleEnablementManagerPhone: '',
                UserEmail: '',
                UpdatedTime: '', }])}
          >
            <UpdateIcon style={{color:'white'}} />
          </Fab></OverlayTrigger>
            </Paper>
            <br/>
         <div className={style.buttonContainer}>
         <Button 
            variant="primary"
            size="sm" 
            className="mr-2 center-button" 
            type='submit' 
            style={{padding:'7px 12px', fontSize:'15px'}}>
            Submit
          </Button>
          
         </div>
        </Form>
    )}
    </Formik>
    </div>
	}
        </>
    )
}
  
export default VendorUpdate;