import { Paper } from '@mui/material'
import React from 'react'
import {Card, Button } from '@trimbleinc/modus-react-bootstrap';

function Profile(props) {
  return (
    <div >
      {props.userName === null ?
      <Card style={{ width: "18rem", position: "absolute", right: "0",top: "130%",transform: "translateY(-50%)", zIndex: "3"}} border="dark" className="shadow">
        <Card.Body className="text-left">
            <Card.Link 
            href="#" 
            onClick={props.sign}
            >
            Login
            </Card.Link>
        </Card.Body>
      </Card>
      :
      <Card style={{ width: "18rem", position: "absolute", right: "0",top: "180%",transform: "translateY(-50%)", zIndex: "3"}} border="dark" className="shadow">
        <Card.Body>
            <Card.Title as="h5" style={{textAlign:"left"}}>{props.userName}</Card.Title>
            <Card.Subtitle style={{textAlign:"left"}}>{props.userEmail}</Card.Subtitle>
        </Card.Body>
        <Card.Body className="text-left">
            <Card.Link 
            href="#" 
            onClick={props.sign}
            >
            Logout
            </Card.Link>
        </Card.Body>
      </Card>
      }
      
    </div>
  )
}

export default Profile