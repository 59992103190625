import React,{Component, useEffect, useState} from "react" 
import {Form, InputGroup, Navbar, Nav, Button, Tooltip, OverlayTrigger } from '@trimbleinc/modus-react-bootstrap';
import Profile from './Profile';
import { useOktaAuth } from '@okta/okta-react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from "@mui/material";

function NavigationMenu(props) {
    // okta profile 
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState({name:"",email:""});
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
    //   setUserInfo(null);
      setUserInfo({name:null,email:null});
    } else {
      // setUserInfo(authState.idToken.claims);\
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const [userRole, setUserRole] = useState([]);

  // Function to collect data
  const getApiData = async (api,setData) => {
    const response = await fetch(
      api
    ).then((response) => response.json())
  setData(response);
  };
  const fetchData = () => {
    getApiData("https://software-procurement-catalog-dev.trimble.cloud/userroleaccessdata",setUserRole);
    }
  useEffect(() => {  
    fetchData();
  }, []);

    const [isCardOpen, setIsCardOpen] = useState(false);

    const handleClick = () => { 
    setIsCardOpen(!isCardOpen);
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const checkUserRoleAccess = (name) => {
    const user = userRole.find(user => user.UserEmail.toLowerCase() === name.toLowerCase());
    if (user) {
      return user.RoleAccess === 'Admin' || 'Write' ? 'Write Access' : 'Read Access';
    }
    return 'Read Access';
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <h3 style={{padding:'20.5px'}}>SW Procurement</h3>
      <Divider/>
      <List>
      {/* <ListItemButton>
        <ListItemText primary='View Products / Domains' onClick={()=>props.page('View Products / Domains')}></ListItemText>
      </ListItemButton>
      <Divider/> */}
        {
          (checkUserRoleAccess(userInfo.email) === 'Write Access' ? ['View Products / Domains','Create/Update Product details', 'Create/Update Domain details', 'Create/Update Vendor details', 'Create/Update Security Assessment', 'Create/Update Contract details']
          : ['View Products / Domains']).map((text, index) => (
            <ListItem key={text} disablePadding>
              {props.currentPage === text ? 
              <ListItemButton style={{backgroundColor:'#DCEDF9'}}>
                <ListItemText primary={text} onClick={()=>props.page(text)}/>
              </ListItemButton> :
              <ListItemButton >
                <ListItemText primary={text} onClick={()=>props.page(text)}/>
              </ListItemButton> }
            </ListItem>
          ))
        }
      <Divider/>
      </List>
    </Box>
  );
  return (
    <div>
      
        <Navbar collapseOnSelect expand="sm" id="example-navbar" className="border navbar-blue" >
{/* /////////////////////////////////////////////////////////////////////////////////////////// */}
          {['left'].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button
              onClick={toggleDrawer(anchor, true)}
              variant="icon-only"
              id="menuButton"
              size="lg"
              data-modus-item="menu-btn"
              data-toggle="#"
              >
                <i className="modus-icon material-icons">menu</i>
              </Button>
              {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
{/* /////////////////////////////////////////////////////////////////////////////////////////// */}


      <Navbar.Brand className=" mr-auto ml-2" href="#">
        <img
          src='https://modus-bootstrap.trimble.com/img/trimble-logo-rev.svg'
          width="107"
          height="25"
          className="img-fluid d-none d-sm-block"
          alt="home"
        />
        <h5 style={{ padding:'5px 0px 0px 0px' ,color:"white"}} className='header-sp'>Software procurement Catalog</h5>
        
      </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
            {userInfo.name === null ? 
            <OverlayTrigger
            key="account"
            placement="bottom"
            overlay={
                // <Tooltip id="notifytooltip"></Tooltip>
            <Tooltip id="notifytooltip" key={userInfo}>
                <div className="text-left">
                Click here to login
                </div>
            </Tooltip>
            }
            >
            <Button
            variant="icon-only"
            id="acntbtn"
            size="lg"
            onClick={handleClick}
            >
            <i className="modus-icon material-icons">account_circle</i>
            {isCardOpen && <Profile sign = {props.sign} userName = {userInfo.name} userEmail = {userInfo.email}/>}
            </Button>
            
            </OverlayTrigger> 
            :
            <>
            <OverlayTrigger
                key="export"
                placement="bottom"
                overlay={
                <Tooltip id="notifytooltip" key={userInfo}>
                    <div className="text-left">
                      Export
                    </div>
                </Tooltip>
                }
            >
              <Button 
              variant="icon-only"
              id="exportbtn"
              size="lg"
              onClick={props.handleOpen}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="mi-outline mi-download" viewBox="0 0 24 24">
                  <path d="M19 13v6H5v-6c0-.55-.45-1-1-1s-1 .45-1 1v7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-7c0-.55-.45-1-1-1s-1 .45-1 1m-6-.33 1.88-1.88a.996.996 0 1 1 1.41 1.41l-3.59 3.59a.996.996 0 0 1-1.41 0L7.7 12.2a.996.996 0 1 1 1.41-1.41l1.88 1.88V4c0-.55.45-1 1-1s1 .45 1 1v8.67Z"/>
                </svg>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
                key="account"
                placement="bottom"
                overlay={
                    // <Tooltip id="notifytooltip"></Tooltip>
                <Tooltip id="notifytooltip" key={userInfo}>
                    <div className="text-left">
                    {userInfo.name}
                    <br />
                    {userInfo.email}
                    </div>
                </Tooltip>
                }
            >
                <Button
                variant="icon-only"
                id="acntbtn"
                size="lg"
                onClick={handleClick}
                >
                <i className="modus-icon material-icons">account_circle</i>
                {isCardOpen && <Profile sign = {props.sign} userName = {userInfo.name} userEmail = {userInfo.email}/>}
                </Button>
                
            </OverlayTrigger>
            </>
            }
            </Nav>
        </Navbar.Collapse>
      </Navbar>
   </div>
  )
}

export default NavigationMenu;