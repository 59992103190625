import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import csv from './icons/csv.png';
import json from './icons/json.png';
import xlsx from './icons/xlsx.png';


function ExportCard(props) {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => {
        setScreenHeight(window.innerHeight);
        setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      
    }, []);

    const [selectAll,setSelectAll]=useState(false);
    const [selectedColumns,setSelectedColumns]=useState({});
    
    // useEffect(()=>{
      
    //   // console.log("Useeffect selected all=",selectAll,"\n",selectedColumns)
    // },[selectedColumns,selectAll])
    
    
    
    const [isColumnOpen, setIsColumnOpen] = useState(false);
    const [format,setFormat] = useState('')
    useEffect(()=>{
      // console.log("Export card",props,props)
      if (props && props.jsonData && props.jsonData.length>0){
        const columns=Object.keys(props.jsonData[0])
        const initialSelectedColumns = columns.reduce((acc, column) => {
          acc[column] = props.defaultSelectedColumns.includes(column);
          return acc;
        }, {});
        // console.log("inside useeffect export card",columns,initialSelectedColumns)
        setSelectedColumns(initialSelectedColumns);
      }
      // console.log("Export card end ")

    },[props.jsonData,isColumnOpen])
    const handlColumnOpen = () => {
      setIsColumnOpen(true);
      props.handleClose();
      };
    
      const handleColumnClose = () => {
        setSelectAll(false)
        setIsColumnOpen(false);
      };

      const handleSelectAll =(e)=>{
        const checked=e.target.checked
        props.handleColumnToggle(checked?'all':'none')
        if (props && props.jsonData && props.jsonData.length>0){
            const obj=selectedColumns
            for(const [k,v] of Object.entries(obj)){
              obj[k]=checked
            }
            setSelectedColumns(obj)
            setSelectAll(checked)  
        }
        
    
      }

  return (
    <>
{/* export card */}
{props.isOpen && (
          <div className="confirmation-card shadow" style={{ width: String(screenWidth*0.35).concat('px'), height:String(screenHeight*0.30).concat('px'), overflow: "auto", position: "absolute",left:'30%', right: "0%",top: "0%",transform: "translateY(120%)", zIndex: "3"}} border="dark">
          <div class="card border-dark shadow" style={{width: String(screenWidth*0.35).concat('px'), height:String(screenHeight*0.30).concat('px'), overflow: "auto",padding:'10px'}}>
          <div class="card-header" style={{display:'flex', justifyContent:'space-between'}}>
              <h3 class="card-title" id="card-title-3" style={{color:'#0063A3', fontWeight:'bold'}}>Choose an export format for the product details:</h3>
              <button onClick={props.handleClose}  class="btn btn-icon-only btn-text-dark">
                <CloseIcon style={{padding:'0px 0px 4px 0px'}}/>
              </button>
          </div>
          <div class="card-body" style={{display:'flex', justifyContent:'space-around'}}>
          <img src={csv} alt="Icon" onClick={()=>{handlColumnOpen();setFormat('csv')}} style={{cursor: 'pointer'}} onMouseOver={(event) => { event.target.style.background = '#CECECE' }} onMouseOut={(event) => { event.target.style.background = 'none' }}/>
          <img src={json} alt="Icon" onClick={()=>{handlColumnOpen();setFormat('json')}} style={{cursor: 'pointer'}} onMouseOver={(event) => { event.target.style.background = '#CECECE' }} onMouseOut={(event) => { event.target.style.background = 'none' }}/>
          <img src={xlsx} alt="Icon" onClick={()=>{handlColumnOpen();setFormat('xlsx')}} style={{cursor: 'pointer'}} onMouseOver={(event) => { event.target.style.background = '#CECECE' }} onMouseOut={(event) => { event.target.style.background = 'none' }}/>
          </div>
          </div>
          </div>
        )
        }
        {/* columns card */}
        {isColumnOpen && (
             <div className="confirmation-card shadow" style={{ width: String(screenWidth-200).concat('px'), height:'auto', overflow: "auto", position: "absolute",left:'7%', right: "0%",top: "0%",transform: "translateY(40%)", zIndex: "3"}} border="dark">
             <div class="card border-dark shadow">
              <div class="card-body" style={{display:'flex', justifyContent:'space-between'}}>
                    <h3 class="card-title" id="card-title-3" >Select columns to export:</h3>
                    <button onClick={handleColumnClose}  class="btn btn-icon-only btn-text-dark">
                      <CloseIcon/>
                    </button>
                </div>
                <div class="form-group" style={{padding:"20px"}}>
                <div class="custom-control custom-checkbox custom-control-inline mr-3">
                    <input type="checkbox" class="custom-control-input" id='select-all' onChange={(e) => {handleSelectAll(e)}} checked={selectAll}/>
                    <label class="custom-control-label" for='select-all' >{selectAll?'Deselect':'Select'} All</label>
                </div>
                <br/>
                {props.jsonData && props.jsonData.length > 0 &&  Object.keys(props.jsonData[0]).sort((a, b) =>
                    props.defaultSelectedColumns.includes(a) && !props.defaultSelectedColumns.includes(b) ? -1 :
                    !props.defaultSelectedColumns.includes(a) && props.defaultSelectedColumns.includes(b) ? 1 :
                    0).map((column) => (
                  props.defaultSelectedColumns.includes(column) ?
                  <div class="custom-control custom-checkbox custom-control-inline mr-3" key={column}>
                    <input type="checkbox" checked class="custom-control-input" id={column} disabled={props.defaultSelectedColumns.includes(column)} />
                    <label class="custom-control-label" for={column} >{column}</label>
                  </div>  
                  :
                  <div class="custom-control custom-checkbox custom-control-inline mr-3" key={column}>
                    <input type="checkbox" class="custom-control-input" id={column} onChange={(e) => {
                      props.handleColumnToggle(column) ;
                      setSelectAll(false);
                      setSelectedColumns({
                        ...selectedColumns,
                        [column]: e.target.checked
                      });
                    }} 
                    checked={ selectedColumns[column]|| selectAll}/>
                    <label class="custom-control-label" for={column} >{column}</label>
                  </div>
                ))}
                </div>
                <div class="card-footer text-right">
                {format === 'csv' ?
                  <button onClick={() => {props.exportToCsv('csv');handleColumnClose()}} class='custom-control-inline mr-3 btn btn-primary'>Export as CSV</button>
                : format === 'json' ?
                <button onClick={() => {props.exportToCsv('json');handleColumnClose()}} class='custom-control-inline mr-3 btn btn-primary'>Export as JSON</button>
                : <button onClick={() => {props.exportToCsv('xlsx');handleColumnClose()}} class='custom-control-inline mr-3 btn btn-primary'>Export as XLSX</button>
              }
                </div>
                </div>
              
               
            </div>
            )}

    </>
  )
}

export default ExportCard