import React from 'react'
import { DropdownButton , Dropdown } from "@trimbleinc/modus-react-bootstrap";

function DropDown(props) {
  return (
    <div >
      <DropdownButton 
      // size='lg'
      menuAlign={"right"}
        id="dropdown-item-button"
        variant="primary"
        title="Domain"
      >
          {props.data &&
          props.data.map((domain,i) => (
            <Dropdown.Item as="button" onClick={() => {props.changeDomain(domain.name)}} >{domain.name}</Dropdown.Item>
          ))}
      </DropdownButton>
    </div>
  )
}


export default DropDown