import '@trimbleinc/modus-react-bootstrap/css/dist/modus-react-bootstrap.min.css';
import {Form, Button} from '@trimbleinc/modus-react-bootstrap';
import React,{useEffect, useState} from "react" 
import Tree from './Components/Tree';
import DropDown from './Components/DropDown';
import Paper from '@mui/material/Paper';
import ProductDetailsPage from './Components/ProductDetailsPage';
import { defineCustomElements } from '@trimble-oss/modus-web-components/loader';
import {useRef} from 'react';
import ExportCard from './ExportCard';

defineCustomElements();
function StandingPage(props) {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const ref = useRef(null);
  const [result, setresult] = useState({
    id:"",
    name:"", 
    ProductDescription : "",
    VendorAccountManagerName : "",
    VendorAccountManagerEmail : "",
    VendorAccountManagerPhone : "",
    VendorTechOwnerName : "",
    VendorTechOwnerEmail : "",
    VendorTechOwnerPhone : "",
    TrimbleEnablementName : "",
    TrimbleEnablementEmail : "",
    TrimbleEnablementPhone : "",
    TrimbleSMEName : "",
    TrimbleSMEEmail : "",
    TrimbleSMEPhone : "",
    ContractStartDate : "",
    ContractEndDate : "",
    ContractStatus : "",
    ContractType : ""
  })
  const [getDomain, setGetDomain] = useState({
    name: "",
    DomainSponserName : "",
    DomainSponserEmail : "",
    DomainSponserPhone : "",
    DomainLeadName : "",
    DomainLeadEmail : "",
    DomainLeadPhone : "",
  })
  const [dropdownselect, setdropdownselect] = useState("")

  // Function to collect data
  const getApiData = async () => {
    const response = await fetch(
      "https://software-procurement-catalog-dev.trimble.cloud/user"
    ).then((response) => response.json());
// console.log('hahaha',response)
    setUsers(response.DomainName);
  };

  // Set search box value based on the user entry
  const handleChange = event => {
    setSearch(event.target.value);
  };

  // clear search input value
  const handleClick = () => {
    setSearch('');
    setdropdownselect('')
    ref.current.value = '';
  };

  // Get product details and domain name
  const resultChange = (val,domain) => {
    setresult(val)
    // console.log(domain)
    setGetDomain(domain)
    // console.log(getDomain)
  }

  // set dropdown based on the search filter
  const changeDomain = (name) => {
    setdropdownselect(name)
  }

  

  // display default first product information
  let isFirstItemProcessed = false; 
  let isFirstPNItemProcessed = false;
  const displayProductPage = () => {
    if (getDomain.name === '' && result.name === ''){
      return (users && users.map((d,i)=>
        {if (!isFirstItemProcessed) {
          isFirstItemProcessed = true;
           return (d.ProductName && d.ProductName.map((productname,j)=>
           {if (!isFirstPNItemProcessed) {
            isFirstPNItemProcessed = true;
              return <ProductDetailsPage domain={d} result={productname}/>
           }}
          ))
        }}
       ))
    } else {
      return <ProductDetailsPage domain={getDomain} result={result}/>
    }
  }
  
  useEffect(() => {
    getApiData();
      }, []);


  // console.log((users))
  return (
    <>
    <div className='container-fluid'>
      <div className='row' style={{padding: "10px"}}>
        <div className='col-4'>
          <Paper elevation={1} style={{padding:"10px",height:String(screenHeight-100).concat('px'), overflow: "auto"}}>
          <div className='col-12'>
            <div className='row'>
              <Form.Control className='col-7 search'
                as="input" 
                ref={ref}
                onChange={handleChange}
                placeholder="Search Product/Vendor Name"
              ></Form.Control>
              <DropDown className='col-3'changeDomain = {changeDomain} data={users && users.filter(domain=>domain.ProductName.some(productname=>productname.name.toLowerCase().includes(search.toLowerCase())))}/>
              <Button variant="icon-only" onClick={handleClick} className="btn-text-dark col-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="mi-outline mi-refresh" viewBox="0 0 24 24">
                <path d="M18.36 5.64a8.976 8.976 0 0 0-7.16-2.61c-4.2.36-7.66 3.73-8.13 7.92C2.46 16.39 6.68 21 12 21c3.59 0 6.67-2.1 8.11-5.13.36-.75-.18-1.62-1.01-1.62-.42 0-.82.22-.99.6-1.07 2.3-3.4 3.9-6.11 3.9-3.62 0-6.7-3.02-6.75-6.64S8.24 5.25 12 5.25c1.87 0 3.53.78 4.75 2l-1.92 1.92c-.63.63-.18 1.71.71 1.71H20c.55 0 1-.45 1-1V5.42c0-.89-1.08-1.34-1.71-.71l-.94.94Z"/>
              </svg>
              </Button>
            </div>
          </div>
            <Tree res={resultChange} data={users && users.filter(domain=>domain.name.includes(dropdownselect) && domain.ProductName.some(productname=>productname.name.toLowerCase().includes(search.toLowerCase())))} search={search}/>
          </Paper>
          
        </div>
        
        <div className='col-8'style={{padding:"10px"}}>
          <Paper elevation={2} style={{padding:"10px",height:String(screenHeight-100).concat('px') ,overflow: "auto"}}>
            {displayProductPage()}
          </Paper>
        </div>
      </div>
      
    </div>
    </>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);

export default StandingPage;