import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
    issuer: 'https://trimble.okta.com/oauth2/default',
    clientId: '0oau785m5mx3b3Fjm357',
  redirectUri: window.location.origin,
});

export const checkSession = async () => {
  try {
    const session = await oktaAuth.session.get();
    if (session['status'] === 'ACTIVE'){
      return true
    }else if (session['status'] === 'INACTIVE'){
      return false
    }
    return !!session; // true if the session exists, false otherwise
  } catch (error) {
    return false; // assume no session if an error occurs
  }
};
 