import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import './App.css';
import NavigationMenu from './Components/NavigationMenu';
import UpdatePage from './UpdatePage';
import { checkSession } from './sessionChecker';

export default withOktaAuth(class Home extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.checkSessionStatus = this.checkSessionStatus.bind(this);
  }

  async componentDidMount() {
    this.sessionCheckInterval = setInterval(this.checkSessionStatus, 5000); // Check every 5 seconds
  }

  componentWillUnmount() {
    clearInterval(this.sessionCheckInterval);
  }

  async checkSessionStatus() {
    const isAuthenticated = await checkSession();
    if (!isAuthenticated) {
      await this.props.oktaAuth.signInWithRedirect();
    }
  }

  async login() {
    await this.props.oktaAuth.signInWithRedirect();
  }

  async logout() {
    
    // this.props.oktaAuth.tokenManager.clear();
    await this.props.oktaAuth.signOut();
  }

  render() {
    let body = null;
    if (this.props.authState?.isAuthenticated) {
      body = (
        <div className="Buttons">
          <UpdatePage sign = {this.logout}/>
        </div>
      );
    } else {
      body = (
        <div className="Buttons">
          <NavigationMenu sign = {this.login}/>
        </div>
      );
    }

    return (
          <div>{body}</div>
    );
  }
});