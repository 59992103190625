import React,{Component, useEffect, useState} from 'react'
import NavigationMenu from './Components/NavigationMenu';
import StandingPage from './StandingPage';
import UnderProcess from './Components/UnderProcess';
import ProductUpdate from './Components/ProductUpdate';
import DomainUpdate from './Components/DomainUpdate';
import VendorUpdate from './Components/VendorUpdate';
import SecurityAssessment from './Components/SecurityAssessment';
import Contract from './Components/ContractUpdate';
import * as XLSX from 'xlsx';
import ExportCard from './ExportCard';

function UpdatePage(props) {
  
  const [page, setPage] = useState('View Products / Domains');

  const changePage = (value) => {
    setPage(value);
  }
 
  // export options
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const getApiData = async () => {
  const response = await fetch(
    "https://software-procurement-catalog-dev.trimble.cloud/exportProductData"
  ).then((response) => response.json());

  setJsonData(response);
  };
  
  useEffect(() => {
    getApiData();
  }, []);
  const [jsonData, setJsonData] = useState([]);
  const defaultSelectedColumns = ['ProductName', 'VendorName']; // Default selected columns
  const [selectedColumns, setSelectedColumns] = useState(defaultSelectedColumns);
  
  const handleColumnToggle = (column) => {

    if(column==='all'){
      setSelectedColumns(Object.keys(jsonData[0]))
    }else if(column==='none'){
      setSelectedColumns(defaultSelectedColumns)
    }else if (selectedColumns.includes(column)) {
      setSelectedColumns(selectedColumns.filter((col) => col !== column));
    } else {
      setSelectedColumns([...selectedColumns, column]);
    }    
  };
  
  const downloadFile = (blob, filename) => {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  
  const exportToCsv = (format) => {
      if (!jsonData || jsonData.length === 0) {
        console.error('No data to export.');
        return;
      }
      let data=jsonData
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      data=data.map((d)=>{
        const startDate=new Date(d.ContractStartDate).toLocaleString('en-US', options)
        const endDate= new Date(d.ContractEndDate).toLocaleString('en-US', options)
        d.ContractStartDate= startDate==='Invalid Date'?'':startDate
        d.ContractEndDate= endDate==='Invalid Date'?'':endDate
        return d
      })
      if (format === "csv") {
        
        const csvHeader = selectedColumns.map(col => `"${col}"`).join(',');
        const csvData = data.map((row) => {
        return selectedColumns.map((col) => `"${row[col]}"`).join(',');
        }).join('\n');
        const csvContent = `${csvHeader}\n${csvData}`;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        downloadFile(blob, 'Product Details.csv')
        setSelectedColumns(defaultSelectedColumns);
        setIsOpen(false);
      }else if (format === 'json'){
        const selectedData = data.map(row => {
          const selectedRow = {};
          selectedColumns.forEach(col => {
            selectedRow[col] = row[col];
          });
          return selectedRow;
        });
        const selectedDataString = JSON.stringify(selectedData, null, 2);
        const blob = new Blob([selectedDataString], { type: 'application/json;charset=utf-8;' });
        downloadFile(blob, 'Product Details.json');
        setSelectedColumns(defaultSelectedColumns);
        setIsOpen(false);
      } else if (format === 'xlsx') {
        const filteredData = data.map(row =>
          selectedColumns.reduce((selectedRow, col) => {
            selectedRow[col] = row[col];
            return selectedRow;
          }, {})
        );
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        downloadFile(dataBlob, 'Product Details.xlsx');
        setSelectedColumns(defaultSelectedColumns);
        setIsOpen(false);
      }
    };

  return (
    <div>
      <NavigationMenu sign = {props.sign} page = {changePage} currentPage = {page} handleOpen = {handleOpen}/>
      <ExportCard isOpen={isOpen} selectedColumns={selectedColumns} 
                  defaultSelectedColumns={defaultSelectedColumns} handleClose={handleClose} 
                  handleColumnToggle={handleColumnToggle} jsonData={jsonData} exportToCsv={exportToCsv}
      />
      {page === 'View Products / Domains' ? <StandingPage/> : 
       page === 'Create/Update Product details' ? <ProductUpdate />:
       page === 'Create/Update Domain details' ? <DomainUpdate/> :
       page === 'Create/Update Vendor details' ? <VendorUpdate/> :
       page === 'Create/Update Security Assessment' ? <SecurityAssessment/> :
       page === 'Create/Update Contract details' ? <Contract/> :
       <UnderProcess/>
      }
      {/* <ProductUpdate existingData={productData} vendorData={vendorData} lifecycleStatusData={lifecycleStatusData} productStatusData={productStatusData} domainData={domainData}/> */}

    </div>
  )
}

export default UpdatePage
